import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'shortId' })
export class ShortIdPipe implements PipeTransform {
  transform(id?: string) {
    if (id != null && typeof id === 'string' && id.length === 15) {
      const segment1 = id.substr(0, 5)
      const segment2 = id.substr(5, 5)
      const segment3 = id.substr(10, 5)
      return `${segment1}-${segment2}-${segment3}`
    } else if (id != null && typeof id === 'string' && id.length === 11) {
      const segment1 = id.substr(0, 4)
      const segment2 = id.substr(4, 3)
      const segment3 = id.substr(7, 4)
      return `${segment1}-${segment2}-${segment3}`
    } else if (id != null && typeof id === 'string' && id.length === 10) {
      const segment1 = id.substr(0, 4)
      const segment2 = id.substr(4, 2)
      const segment3 = id.substr(6, 4)
      return `${segment1}-${segment2}-${segment3}`
    } else if (id != null && typeof id === 'string' && id.length === 36) {
      return id.substr(0, 8)
    } else {
      return id ?? ''
    }
  }
}
