<mat-toolbar color="primary" class="topbar telative">
  <button mat-icon-button (click)="snav.toggle()" value="sidebarclosed">
    <mat-icon>menu</mat-icon>
  </button>

  <div class="navbar-header m-l-10">
    <a class="navbar-brand" href="/">
      <app-mmx-icon [paused]="true" color="inverted"></app-mmx-icon>
    </a>
  </div>
  <span fxFlex></span>
  <app-header></app-header>
</mat-toolbar>

<mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
  <mat-sidenav #snav id="snav" class="dark-sidebar pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'"
               fixedTopGap="0" [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches">
    <app-sidebar></app-sidebar>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet>
      <app-spinner></app-spinner>
    </router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
