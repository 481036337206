import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { ConnectionService } from 'app/core/services/connection.service'
import { ConnectionActions, IntegrationApplicationActions } from 'app/store/actions'
import { ConnectionSelectors } from 'app/store/selectors'
import { get } from 'lodash'
import { of } from 'rxjs'
import { catchError, filter, map, switchMap } from 'rxjs/operators'

@Injectable()
export class ConnectionEffects {
  loadAll$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConnectionActions.loadAllConnections),
        switchMap(() => {
          return this.connectionService.getAll().pipe(
            map((connections) => {
              return ConnectionActions.loadAllConnectionsSuccess({ connections })
            }),
            catchError(() => {
              return of(ConnectionActions.loadAllConnectionsFailure({ error: 'Cannot load all connections' }))
            }),
          )
        }),
      ),
    { useEffectsErrorHandler: false },
  )

  load$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConnectionActions.loadConnection),
        switchMap((action) => {
          return this.connectionService.get(action.id).pipe(
            map((connection) => {
              return ConnectionActions.loadConnectionSuccess({ connection })
            }),
            catchError(() => {
              this.router.navigateByUrl('/connections')
              return of(ConnectionActions.loadConnectionFailure({ error: 'Cannot load connection' }))
            }),
          )
        }),
      ),
    { useEffectsErrorHandler: false },
  )

  start$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConnectionActions.startConnection),
        switchMap(({ id }) => {
          return this.connectionService.startConnection(id).pipe(
            map(() => {
              this.snackBar.open('Connection started', 'close', { duration: 3000 })
              return ConnectionActions.startConnectionSuccess({ id })
            }),
            catchError(() => {
              this.snackBar.open('Cannot start connection', 'close', { duration: 3000 })
              return of(ConnectionActions.startConnectionFailure({ error: 'Cannot start connection' }))
            }),
          )
        }),
      ),
    { useEffectsErrorHandler: false },
  )

  stop$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConnectionActions.stopConnection),
        switchMap(({ id }) => {
          return this.connectionService.stopConnection(id).pipe(
            map(() => {
              this.snackBar.open('Connection stopped', 'close', { duration: 3000 })
              return ConnectionActions.stopConnectionSuccess({ id })
            }),
            catchError(() => {
              this.snackBar.open('Cannot stop connection', 'close', { duration: 3000 })
              return of(ConnectionActions.stopConnectionFailure({ error: 'Cannot stop connection' }))
            }),
          )
        }),
      ),
    { useEffectsErrorHandler: false },
  )

  create$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConnectionActions.createConnection),
        switchMap(({ connection }) => {
          return this.connectionService.create(connection).pipe(
            map((newConnection) => {
              this.dialog.closeAll()
              this.snackBar.open('Connection created', 'close', { duration: 3000 })
              return ConnectionActions.createConnectionSuccess({ connection: newConnection })
            }),
            catchError(() => {
              this.snackBar.open('Cannot create connection', 'close', { duration: 3000 })
              return of(ConnectionActions.createConnectionFailure({ error: 'Cannot create connection' }))
            }),
          )
        }),
      ),
    { useEffectsErrorHandler: false },
  )

  update$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConnectionActions.updateConnection),
        map((action) => action.connection),
        switchMap((connection) => {
          return this.connectionService.update(connection).pipe(
            map(() => {
              this.dialog.closeAll()
              this.snackBar.open('Connection updated', 'close', { duration: 3000 })
              return ConnectionActions.updateConnectionSuccess({ connection })
            }),
            catchError((err: any) => {
              const message = get(err, 'error.errors[0].message', '')
              this.snackBar.open(`Cannot update connection: ${message}`, 'close', { duration: 3000 })
              return of(ConnectionActions.updateConnectionFailure({ error: 'Cannot update connection' }))
            }),
          )
        }),
      ),
    { useEffectsErrorHandler: false },
  )

  delete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ConnectionActions.deleteConnection),
        map((action) => action.connection),
        switchMap((connection) => {
          return this.connectionService.delete(connection.id).pipe(
            map(() => {
              this.dialog.closeAll()
              this.snackBar.open('Connection deleted', 'close', { duration: 3000 })
              return ConnectionActions.deleteConnectionSuccess({ connection })
            }),
            catchError((err: any) => {
              const message = get(err, 'error.errors[0].message', '')
              this.snackBar.open(`Cannot delete connection: ${message}`, 'close', { duration: 3000 })
              return of(ConnectionActions.deleteConnectionFailure({ error: 'Cannot update connection' }))
            }),
          )
        }),
      ),
    { useEffectsErrorHandler: false },
  )

  loadConnectionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionActions.loadConnectionSuccess),
      map(({ connection }) => IntegrationApplicationActions.loadApplication({ id: connection.clientId })),
    ),
  )

  sendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionActions.sendMessage),
      concatLatestFrom(({ message, controlId, connection }) => {
        return this.connectionSelectors.getMessageSignature(message, controlId, connection)
      }),
      switchMap(([{ connection, controlId, message }, signature]) =>
        this.connectionService.sendMessage(connection.id, message, { signature, controlId }).pipe(
          map(() => {
            this.dialog.closeAll()
            this.snackBar.open('Message is sent', 'close', { duration: 3000 })
            return ConnectionActions.sendMessageSuccess({ connection })
          }),
          catchError((err: any) => {
            const message = get(err, 'error.errors[0].message', '')
            this.snackBar.open(`Message sending failure: ${message}`, 'close', { duration: 3000 })
            return of(ConnectionActions.sendMessageFailure())
          }),
        ),
      ),
    ),
  )

  createAlarms$ = createEffect(() => this.actions$.pipe(
    ofType(ConnectionActions.createAlarms),
    switchMap(({ connectionId, thresholds }) => {
      return this.connectionService.createAlarms(connectionId, thresholds).pipe(
        map(() => {
          this.snackBar.open('Alarms created', 'close', { duration: 3000 })
        }),
        catchError(() => {
          this.snackBar.open('Alarms creation error', 'close', { duration: 3000 })
          return of(null)
        }),
      )
    })), { dispatch: false },
  )

  deleteAlarms$ = createEffect(() => this.actions$.pipe(
    ofType(ConnectionActions.deleteAlarms),
    switchMap(({ connectionId }) => {
      return this.connectionService.deleteAlarms(connectionId).pipe(
        map(() => {
          this.snackBar.open('Alarms deleted', 'close', { duration: 3000 })
        }),
        catchError(() => {
          this.snackBar.open('Alarms deletion error', 'close', { duration: 3000 })
          return of(null)
        }),
      )
    })), { dispatch: false },
  )

  reloadConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionActions.startConnectionSuccess, ConnectionActions.stopConnectionSuccess),
      map(({ id }) => ConnectionActions.loadConnection({ id })),
    ),
  )

  loadIp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionActions.loadHL7ServerIp),
      concatLatestFrom(() => this.connectionSelectors.hl7ServerIp$),
      filter(([, ip]) => !ip),
      switchMap(() => this.connectionService.getHL7ServerIP().pipe(
        map((response) => ConnectionActions.loadHL7ServerIpSuccess({ hl7ServerIp: response.ip })),
      )),
    ),
  )

  constructor(
    private actions$: Actions,
    private connectionService: ConnectionService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private connectionSelectors: ConnectionSelectors,
  ) {}

}
