import { Injectable } from '@angular/core'
import { createFeatureSelector, createSelector, Store } from '@ngrx/store'
import { map } from 'rxjs'

import { AppState } from '../'
import { AvailableDefinitionState, selectAllAvailableDefinitions } from '../reducers/available-definition.reducer'

const getAvailableDefinitionState = createFeatureSelector<AvailableDefinitionState>('availableDefinition')

const getAvailableDefinitions = createSelector(
  getAvailableDefinitionState,
  selectAllAvailableDefinitions,
)

const getLoading = createSelector(
  getAvailableDefinitionState,
  (state) => state.loading,
)

@Injectable({
  providedIn: 'root',
})
export class AvailableDefinitionSelectors {
  availableDefinitions$ = this.store.select(getAvailableDefinitions)
  loading$ = this.store.select(getLoading)

  constructor(private store: Store<AppState>) {}

  public getConfiguration(definitionId: string) {
    return this.store.select(getAvailableDefinitions).pipe(
      map((definitions) => {
        const software = definitions.find((definition) => definition.id === definitionId)
        if (!software || !software.configuration) {
          return null
        }

        return software.configuration
      }),
    )
  }

  public getDefinition(definitionId: string) {
    return this.store.select(getAvailableDefinitions).pipe(
      map((definitions) => definitions.find((definition) => definition.id === definitionId)),
    )
  }
}
