import { AfterViewInit, Component, OnInit } from '@angular/core'
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private router: Router,
  ) {}

  ngOnInit() {
    const appLoader = document.getElementById('app-loader')

    if (appLoader) {
      appLoader.style.display = 'none'
    }
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        document.getElementById('app-loader').style.display = 'flex'
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        document.getElementById('app-loader').style.display = 'none'
      }
    })
  }
}
