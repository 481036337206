import { Action, createReducer, on } from '@ngrx/store'

import * as AuthActions from '../actions/auth.actions'

export interface AuthState {
  loading: boolean
}

export const initialState: AuthState = {
  loading: false,
}

const authReducer = createReducer(
  initialState,
  on(AuthActions.logIn, (state) => ({ ...state, loading: true })),
  on(AuthActions.logInSuccess, (state) => ({
    ...state,
    loading: false,
  })),
  on(AuthActions.logInFailure, (state) => ({
    ...state,
    loading: false,
  })),
  on(AuthActions.logOut, () => initialState),
)

export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action)
}
