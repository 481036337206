import { Injectable } from '@angular/core'
import { createFeatureSelector, createSelector, Store } from '@ngrx/store'
import { templateRuleConnectionId } from 'app/models'

import { AppState } from '../'
import { RuleState, selectAllRules } from '../reducers/rule.reducer'
import { getCurrentConnection } from './connection.selectors'

const getRuleState = createFeatureSelector<RuleState>('rule')

const getRules = createSelector(
  getRuleState,
  selectAllRules,
)

const getLoading = createSelector(
  getRuleState,
  (state) => state.loading,
)

const getCurrentConnectionRules = createSelector(
  getRules,
  getCurrentConnection,
  (rules, connection) => {
    const connectionId = connection?.id ?? templateRuleConnectionId
    return rules.filter((rule) => rule.connectionId === connectionId)
  },
)

@Injectable({
  providedIn: 'root',
})
export class RuleSelectors {
  rules$ = this.store.select(getRules)
  loading$ = this.store.select(getLoading)
  currentConnectionRules$ = this.store.select(getCurrentConnectionRules)

  constructor(private store: Store<AppState>) {}
}
