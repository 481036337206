import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'mask',
})
export class MaskTextPipe implements PipeTransform {
  transform(value: string) {
    if (typeof value === 'string') {
      return '*'.repeat(value.length > 30 ? 30 : value.length)
    } else {
      return value
    }
  }
}
