import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { get } from 'lodash'
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import { IntegrationCustomerService } from '../../core/services'
import { ConnectionActions } from '../actions'
import * as IntegrationCustomerActions from '../actions/integration-customer.actions'

@Injectable()
export class IntegrationCustomerEffects {
  loadAll$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          IntegrationCustomerActions.loadAllCustomers,
          ConnectionActions.loadAllConnections,
        ),
        switchMap(() => {
          return this.integrationCustomerService.getAll().pipe(
            map((customers) => {
              return IntegrationCustomerActions.loadAllCustomersSuccess({ customers })
            }),
            catchError(() => {
              return of(IntegrationCustomerActions.loadAllCustomersFailure({ error: 'Cannot load all integration customers' }))
            }),
          )
        }),
      ),
    { useEffectsErrorHandler: false },
  )

  load$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationCustomerActions.loadCustomer),
        switchMap((action) => {
          return this.integrationCustomerService.get(action.id).pipe(
            map((customer) => {
              return IntegrationCustomerActions.loadCustomerSuccess({ customer })
            }),
            catchError(() => {
              return of(IntegrationCustomerActions.loadCustomerFailure({ error: 'Cannot load integration customer' }))
            }),
          )
        }),
      ),
    { useEffectsErrorHandler: false },
  )

  create$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationCustomerActions.createCustomer),
        switchMap(({ name }) => {
          return this.integrationCustomerService.create(name).pipe(
            map((newCustomer) => {
              this.dialog.closeAll()
              this.snackBar.open('Integration application created', 'close', { duration: 3000 })
              return IntegrationCustomerActions.createCustomerSuccess({ customer: newCustomer })
            }),
            catchError(() => {
              this.snackBar.open('Cannot create integration application', 'close', { duration: 3000 })
              return of(IntegrationCustomerActions.createCustomerFailure({ error: 'Cannot create integration customer' }))
            }),
          )
        }),
      ),
    { useEffectsErrorHandler: false },
  )

  update$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationCustomerActions.updateCustomer),
        map((action) => action.customer),
        switchMap((customer) => {
          return this.integrationCustomerService.update(customer).pipe(
            map((customer) => {
              this.dialog.closeAll()
              this.snackBar.open('Integration application updated', 'close', { duration: 3000 })
              return IntegrationCustomerActions.updateCustomerSuccess({ customer })
            }),
            catchError((err: HttpErrorResponse) => {
              const message = get(err, 'error.errors[0].message', '')
              this.snackBar.open(`Cannot update customer: ${message}`, 'close', { duration: 3000 })
              return of(IntegrationCustomerActions.updateCustomerFailure({ error: 'Cannot update integration customer' }))
            }),
          )
        }),
      ),
    { useEffectsErrorHandler: false },
  )

  delete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationCustomerActions.deleteCustomer),
        switchMap(({ customer }) => {
          return this.integrationCustomerService.delete(customer.id).pipe(
            map(() => {
              this.dialog.closeAll()
              this.snackBar.open('Application deleted', 'close', { duration: 3000 })
              return IntegrationCustomerActions.deleteCustomerSuccess({ customer })
            }),
            catchError((err: HttpErrorResponse) => {
              const message = get(err, 'error.errors[0].message', '')
              this.snackBar.open(`Cannot delete Application: ${message}`, 'close', { duration: 3000 })
              return of(IntegrationCustomerActions.deleteCustomerFailure({ error: 'Cannot delete customer' }))
            }),
          )
        }),
      ),
  )

  constructor(
    private actions$: Actions,
    private integrationCustomerService: IntegrationCustomerService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}
}
