import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { IntegrationCustomer } from 'app/models'

import { ConnectionActions, IntegrationCustomerActions } from '../actions'

export interface IntegrationCustomerState extends EntityState<IntegrationCustomer>{
  loading: boolean
  currentCustomer: string | null
}

const adapter: EntityAdapter<IntegrationCustomer> = createEntityAdapter<IntegrationCustomer>()

const initialState: IntegrationCustomerState = adapter.getInitialState({
  loading: false,
  currentCustomer: null,
})

const customerReducer = createReducer(
  initialState,
  on(
    IntegrationCustomerActions.loadAllCustomers,
    IntegrationCustomerActions.createCustomer,
    IntegrationCustomerActions.updateCustomer,
    IntegrationCustomerActions.deleteCustomer,
    ConnectionActions.loadConnection,
    (state) => ({
      ...state,
      loading: true,
    }),
  ),
  on(IntegrationCustomerActions.loadAllCustomersSuccess, (state, { customers }) => {
    return adapter.setAll(customers, {
      ...state,
      loading: false,
    })
  }),
  on(
    IntegrationCustomerActions.loadAllCustomersFailure,
    IntegrationCustomerActions.loadCustomerSuccess,
    IntegrationCustomerActions.updateCustomerFailure,
    IntegrationCustomerActions.createCustomerFailure,
    IntegrationCustomerActions.deleteCustomer,
    (state) => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    IntegrationCustomerActions.loadCustomerFailure,
    (state) => ({
      ...state,
      currentCustomer: null,
      loading: false,
    }),
  ),
  on(IntegrationCustomerActions.loadCustomer, (state, { id }) => ({
    ...state,
    currentCustomer: id,
    loading: true,
  })),
  on(
    IntegrationCustomerActions.loadCustomerSuccess,
    IntegrationCustomerActions.createCustomerSuccess,
    IntegrationCustomerActions.updateCustomerSuccess,
    (state, { customer }) => {
      return adapter.upsertOne(customer, {
        ...state,
        loading: false,
      })
    }),
  on(IntegrationCustomerActions.deleteCustomerSuccess, (state, { customer }) => {
    return adapter.removeOne(customer.id, { ...state, loading: false })
  }),
)

export function reducer(state: IntegrationCustomerState | undefined, action: Action) {
  return customerReducer(state, action)
}

const {
  selectEntities,
  selectAll,
} = adapter.getSelectors()

export const selectCustomerEntities = selectEntities
export const selectAllCustomers = selectAll
