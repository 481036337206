import { Component } from '@angular/core'

import { AuthDispatchers } from '../../../../store/'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [],
})
export class AppHeaderComponent {
  constructor(private authDispatchers: AuthDispatchers) {}

  logOut(): void {
    this.authDispatchers.logOut()
  }
}
