<mat-card class="page-card searchable pageable">
  <mat-progress-bar *ngIf="loading$ | async" mode="indeterminate" class="session-progress"></mat-progress-bar>

  <mat-card-header fxLayoutAlign="space-between center">
    <mat-card-title>
      Logs
    </mat-card-title>
    <button mat-icon-button (click)="refresh()">
      <mat-icon>refresh</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field appearance="outline" class="w-100 hide-hint">
      <input
        matInput
        placeholder="Search Log"
        (keyup)="applyFilter($any($event.target).value)"
      />
      <mat-icon matSuffix>
        search
      </mat-icon>
    </mat-form-field>

    <section class="responsive-table">
      <mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">
        <ng-container matColumnDef="connectionId">
          <mat-header-cell fxFlex="40" *matHeaderCellDef mat-sort-header> Connection Id </mat-header-cell>
          <mat-cell fxFlex="40" *matCellDef="let element"> {{ element.connectionId }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="message">
          <mat-header-cell fxFlex="40" *matHeaderCellDef mat-sort-header> Message </mat-header-cell>
          <mat-cell fxFlex="40" *matCellDef="let element">
            <mat-icon *ngIf="element.logType === 2" color="warn">warning_amber</mat-icon>
            <mat-icon *ngIf="element.logType === 1" color="accent">warning_amber</mat-icon>
            <mat-icon *ngIf="element.logType === 0" color="primary">warning_amber</mat-icon>
            {{ element.message }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="source">
          <mat-header-cell fxFlex="20" *matHeaderCellDef mat-sort-header> Source </mat-header-cell>
          <mat-cell fxFlex="20" *matCellDef="let element"> {{ element.source }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <mat-header-cell fxFlex="40" *matHeaderCellDef mat-sort-header> Time </mat-header-cell>
          <mat-cell fxFlex="40" *matCellDef="let element"> {{ element.createdAt }} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </section>
  </mat-card-content>
  <mat-card-footer>
    <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[20, 50, 100]" (page)="onPage($event)">
    </mat-paginator>
  </mat-card-footer>
</mat-card>

