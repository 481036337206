import { Injectable } from '@angular/core'
import { alarmThresholds } from '@integrations/shared'
import { Store } from '@ngrx/store'
import { IntegrationConnection } from 'app/models'

import { IntegrationApplicationActions } from '../actions'
import * as ConnectionActions from '../actions/connection.actions'
import { AppState } from '../reducers/index'

@Injectable()
export class ConnectionDispatchers {
  constructor(private store: Store<AppState>) {}

  create(connection: Partial<IntegrationConnection>) {
    this.store.dispatch(ConnectionActions.createConnection({ connection }))
  }

  update(connection: IntegrationConnection) {
    this.store.dispatch(ConnectionActions.updateConnection({ connection }))
  }

  delete(connection: IntegrationConnection) {
    this.store.dispatch(ConnectionActions.deleteConnection({ connection }))
  }

  start(id: string) {
    this.store.dispatch(ConnectionActions.startConnection({ id }))
  }

  stop(id: string) {
    this.store.dispatch(ConnectionActions.stopConnection({ id }))
  }

  load(id: string) {
    this.store.dispatch(ConnectionActions.loadConnection({ id }))
  }

  loadAll(includeApplications = true) {
    if (includeApplications) {
      this.store.dispatch(IntegrationApplicationActions.loadAllApplications())
    }
    this.store.dispatch(ConnectionActions.loadAllConnections())
  }

  reset() {
    this.store.dispatch(ConnectionActions.resetConnection())
  }

  sendMessage(message: string, controlId: string, connection: IntegrationConnection) {
    this.store.dispatch(ConnectionActions.sendMessage({ message, controlId, connection }))
  }

  createAlarms(connectionId: string, thresholds: alarmThresholds) {
    this.store.dispatch(ConnectionActions.createAlarms({ connectionId, thresholds }))
  }

  deleteAlarms(connectionId: string) {
    this.store.dispatch(ConnectionActions.deleteAlarms({ connectionId }))
  }

  loadServerIp() {
    this.store.dispatch(ConnectionActions.loadHL7ServerIp())
  }
}
