import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of, switchMap } from 'rxjs'
import { catchError, concatMap, map, tap } from 'rxjs/operators'

import { CognitoService, IUser } from '../../core/services'
import * as AuthActions from '../actions/auth.actions'

@Injectable()
export class AuthEffects {
  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logIn),
        concatMap(({ email, password }) =>
          this.cognitoService.signIn({ email, password } as IUser).pipe(
            map(() => AuthActions.logInSuccess()),
            catchError(() => of(AuthActions.logInFailure({ error: 'Wrong credentials' }))),
          ),
        ),
      ),
    { useEffectsErrorHandler: false },
  )

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logInSuccess),
        tap(() => {
          this.cognitoService.authenticationSubject.next(true)
          this.router.navigateByUrl('/applications')
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: false },
  )

  loginFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logInFailure),
        tap((action) => {
          if (action.error) {
            this.snackBar.open(action.error, 'close', { duration: 3000 })
            this.router.navigateByUrl('/log-in')
          }
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: false },
  )

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logOut),
        switchMap(() => this.cognitoService.signOut()),
        tap(() => {
          this.router.navigateByUrl('/log-in')
        }),
      ),
    { dispatch: false, useEffectsErrorHandler: false },
  )

  sessionExpired$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.sessionExpired),
        map(({ message }) => {
          this.snackBar.open(message, 'close', { duration: 3000 })
          this.dialogRef.closeAll()
          return AuthActions.logOut()
        }),
      ),
    { useEffectsErrorHandler: false },
  )

  constructor(
    private actions$: Actions,
    private cognitoService: CognitoService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialog,
  ) {}
}
