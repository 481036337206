import { Injectable } from '@angular/core'
import { createFeatureSelector, createSelector, Store } from '@ngrx/store'

import { AppState } from '../reducers'
import { IntegrationCustomerState, selectAllCustomers, selectCustomerEntities } from '../reducers/integration-customer.reducer'

export const getCustomerState = createFeatureSelector<IntegrationCustomerState>('customer')

export const getCustomers = createSelector(
  getCustomerState,
  selectAllCustomers,
)

export const getAllCustomers = createSelector(
  getCustomerState,
  (customers) => customers.entities,
)

export const getCurrentCustomerId = createSelector(
  getCustomerState,
  (state) => state.currentCustomer,
)

export const getCustomerEntities = createSelector(
  getCustomerState,
  selectCustomerEntities,
)

export const getCurrentCustomer = createSelector(
  getCustomerEntities,
  getCurrentCustomerId,
  (entities, customerId) => customerId && entities[customerId],
)

export const getCustomerLoading = createSelector(
  getCustomerState,
  (state: IntegrationCustomerState) => state.loading,
)

@Injectable()
export class IntegrationCustomerSelectors {
  customers$ = this.store.select(getCustomers)
  currentCustomer$ = this.store.select(getCurrentCustomer)
  loading$ = this.store.select(getCustomerLoading)

  constructor(private store: Store<AppState>) {}
}
