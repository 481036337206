<div class="login-register">
  <div class="login-register-box">
    <mat-progress-bar *ngIf="this.loading$ | async"  mode="indeterminate" class="session-progress"></mat-progress-bar>
    <mat-card>
      <mat-card-content>
        <form [formGroup]="signInForm" (ngSubmit)="onSubmit()">
          <div class="text-center">
            <app-mmx-icon [paused]="true"></app-mmx-icon>
            <h4 class="m-t-30">Integration Admin Console</h4>
          </div>

          <div fxLayout="row wrap">
            <!-- col full-->
            <div fxFlex.gt-sm="100" fxFlex="100">
              <mat-form-field appearance="outline">
                <mat-label>Email address</mat-label>
                <input matInput type="email" formControlName="email" required>
                <mat-error>Email is required.</mat-error>
              </mat-form-field>
            </div>
            <!-- col full-->
            <div fxFlex.gt-sm="100" fxFlex="100">
              <mat-form-field appearance="outline">
                <mat-label>Password</mat-label>
                <input matInput type="password" formControlName="password" required>
                <mat-error>Password is required.</mat-error>
              </mat-form-field>
            </div>
            <!-- col full-->
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <button mat-raised-button color="primary" class="w-100" type="submit" [disabled]="!signInForm.valid">
                Login
              </button>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
