import { Injectable } from '@angular/core'
import { createFeatureSelector, createSelector, Store } from '@ngrx/store'
import { HistoryMessage } from 'app/models'
import { combineLatestWith } from 'rxjs'
import { map } from 'rxjs/operators'

import { AppState } from '../'
import { MessageHistoryState, selectAllMessages } from '../reducers/message-history.reducer'
import { getCurrentConnection } from './connection.selectors'

const getMessageHistoryState = createFeatureSelector<MessageHistoryState>('messageHistory')

const getMessages = createSelector(
  getMessageHistoryState,
  selectAllMessages,
)

const getLoading = createSelector(
  getMessageHistoryState,
  (state) => state.loading,
)

const getQueryParam = createSelector(
  getMessageHistoryState,
  getCurrentConnection,
  (messageHistoryState, currentConnection) => {
    const params: { pageSize: number, lastEvaluatedKey?: string, connectionId?: string } = { pageSize: messageHistoryState.pageSize }

    if (messageHistoryState.lastEvaluatedKey) {
      params.lastEvaluatedKey = messageHistoryState.lastEvaluatedKey
    }

    if (currentConnection) {
      params.connectionId = currentConnection.id
    }

    return params
  },
)

const hasNext = createSelector(
  getMessageHistoryState,
  (state) => !!state.lastEvaluatedKey,
)

@Injectable({
  providedIn: 'root',
})
export class MessageHistorySelectors {
  messages$ = this.store.select(getMessages)
  loading$ = this.store.select(getLoading)
  hasNext$ = this.store.select(hasNext)
  queryParam$ = this.store.select(getQueryParam)
  messagesWithMeta$ = this.messages$.pipe(
    combineLatestWith(this.store.select(hasNext)),
    map(([messages, hasNext]): { messages: HistoryMessage[], hasNext: boolean } => ({
      messages,
      hasNext,
    })),
  )
  constructor(private store: Store<AppState>) {}
}
