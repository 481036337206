import { ActionReducerMap } from '@ngrx/store'

import * as fromAuth from './auth.reducer'
import * as fromAvailableDefinition from './available-definition.reducer'
import * as fromConnection from './connection.reducer'
import * as fromIntegrationApplication from './integration-application.reducer'
import * as fromIntegrationCustomer from './integration-customer.reducer'
import * as fromJobHistory from './job-history.reducer'
import * as fromLog from './log.reducer'
import * as fromMessageHistory from './message-history.reducer'
import * as fromRPADefinition from './rpa-definition.reducer'
import * as fromRule from './rule.reducer'

export interface AppState {
  auth: fromAuth.AuthState
  application: fromIntegrationApplication.IntegrationApplicationState
  availableDefinition: fromAvailableDefinition.AvailableDefinitionState
  connection: fromConnection.ConnectionState
  customer: fromIntegrationCustomer.IntegrationCustomerState
  log: fromLog.LogState
  messageHistory: fromMessageHistory.MessageHistoryState
  jobHistory: fromJobHistory.JobHistoryState
  rpaDefinition: fromRPADefinition.RPADefinitionState
  rule: fromRule.RuleState
}

export const reducers: ActionReducerMap<AppState> = {
  auth: fromAuth.reducer,
  application: fromIntegrationApplication.reducer,
  availableDefinition: fromAvailableDefinition.reducer,
  connection: fromConnection.reducer,
  customer: fromIntegrationCustomer.reducer,
  log: fromLog.reducer,
  messageHistory: fromMessageHistory.reducer,
  jobHistory: fromJobHistory.reducer,
  rpaDefinition: fromRPADefinition.reducer,
  rule: fromRule.reducer,
}
