import { Injectable } from '@angular/core'
import { createFeatureSelector, createSelector, Store } from '@ngrx/store'
import { RPADefinition } from 'app/models'
import { combineLatestWith, map } from 'rxjs'

import { AppState } from '../'
import { RPADefinitionState, selectAllDefinitions } from '../reducers/rpa-definition.reducer'

const getRPADefinitionState = createFeatureSelector<RPADefinitionState>('rpaDefinition')

const getDefinitions = createSelector(
  getRPADefinitionState,
  selectAllDefinitions,
)

const getLoading = createSelector(
  getRPADefinitionState,
  (state) => state.loading,
)

const hasNext = createSelector(
  getRPADefinitionState,
  (state) => !!state.lastEvaluatedKey,
)

@Injectable({
  providedIn: 'root',
})
export class RPADefinitionSelectors {
  definitions$ = this.store.select(getDefinitions)
  loading$ = this.store.select(getLoading)
  hasNext$ = this.store.select(hasNext)
  definitionsWithMeta$ = this.definitions$.pipe(
    combineLatestWith(this.store.select(hasNext)),
    map(([definitions, hasNext]): { definitions: RPADefinition[], hasNext: boolean } => ({
      definitions,
      hasNext,
    })),
  )

  constructor(private store: Store<AppState>) {}
}
