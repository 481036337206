import { MediaMatcher } from '@angular/cdk/layout'
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core'
import { Subject } from 'rxjs'

import { AuthDispatchers } from '../../../../store'

export interface MenuItem {
  state: string
  name: string
  type: string
  icon: string
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [],
})
export class AppSidebarComponent implements OnDestroy {

  menuItems: MenuItem[] = [
    {
      state: 'customers',
      name: 'Customers',
      type: 'link',
      icon: 'web',
    },
    {
      state: 'applications',
      name: 'Applications',
      type: 'link',
      icon: 'web',
    },
    {
      state: 'connections',
      name: 'Connections',
      type: 'link',
      icon: 'account_tree',
    },
    {
      state: 'logs',
      name: 'Logs',
      type: 'link',
      icon: 'warning_amber',
    },
    {
      state: 'rules',
      name: 'Rule Templates',
      type: 'link',
      icon: 'design_services',
    },
  ]

  mobileQuery: MediaQueryList

  private _mobileQueryListener: () => void
  private onDestroy$: Subject<void> = new Subject<void>()

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private authDispatchers: AuthDispatchers,
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)')
    this._mobileQueryListener = () => changeDetectorRef.detectChanges()
    this.mobileQuery.addEventListener('change', this._mobileQueryListener)
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener)
    this.onDestroy$.next()
    this.onDestroy$.complete()
  }

  logOut(): void {
    this.authDispatchers.logOut()
  }
}
