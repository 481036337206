import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { Amplify, Auth } from 'aws-amplify'

import { environment } from '../environments/environment'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { LogInComponent } from './components/log-in/log-in.component'
import { FullComponent } from './core/layouts/full/full.component'
import { AppHeaderComponent } from './core/layouts/full/header/header.component'
import { AppSidebarComponent } from './core/layouts/full/sidebar/sidebar.component'
import { ConnectionService, IntegrationApplicationService, IntegrationCustomerService, LogService } from './core/services'
import { AuthGuardService as AuthGuard } from './core/services/auth-guard.service'
import { CognitoService } from './core/services/cognito.service'
import { ErrorInterceptor } from './core/services/error.interceptor'
import { RestService } from './core/services/rest.service'
import { SpinnerComponent } from './shared/components/spinner/spinner.component'
import { SharedModule } from './shared/shared.module'
import { AppStoreModule } from './store/app-store.module'

Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'myAPIName',
        endpoint: environment.api,
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        }),
      },
    ],
  },
})

@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    AppSidebarComponent,
    FullComponent,
    LogInComponent,
    SpinnerComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    AppStoreModule,
  ],
  providers: [
    AuthGuard,
    IntegrationApplicationService,
    IntegrationCustomerService,
    ConnectionService,
    CognitoService,
    LogService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    RestService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
