import { Injectable } from '@angular/core'
import { IntegrationCustomer } from 'app/models'
import { Observable } from 'rxjs'

import { RestService } from './rest.service'

@Injectable()
export class IntegrationCustomerService {
  private readonly route = '/customer'

  constructor(private restService: RestService) {}

  getAll(): Observable<IntegrationCustomer[]> {
    return this.restService.get<IntegrationCustomer[]>(this.route)
  }

  get(id: string): Observable<IntegrationCustomer> {
    return this.restService.get<IntegrationCustomer>(`${this.route}/${id}`)
  }

  create(name: string): Observable<IntegrationCustomer> {
    return this.restService.post<IntegrationCustomer>(this.route, { name })
  }

  update(data: IntegrationCustomer) {
    return this.restService.put<IntegrationCustomer>(`${this.route}/${data.id}`, data)
  }

  delete(id: string) {
    return this.restService.delete(`${this.route}/${id}`)
  }
}

