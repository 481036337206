import { AuthEffects } from './auth.effects'
import { AvailableDefinitionEffects } from './available-definition.effects'
import { ConnectionEffects } from './connection.effects'
import { IntegrationApplicationEffects } from './integration-application.effects'
import { IntegrationCustomerEffects } from './integration-customer.effects'
import { JobHistoryEffects } from './job-history.effects'
import { LogEffects } from './log.effects'
import { MessageHistoryEffects } from './message-history.effects'
import { RPADefinitionEffects } from './rpa-definition.effects'
import { RuleEffects } from './rule.effects'

export const effects: any[] = [
  AuthEffects,
  AvailableDefinitionEffects,
  IntegrationApplicationEffects,
  IntegrationCustomerEffects,
  ConnectionEffects,
  LogEffects,
  MessageHistoryEffects,
  JobHistoryEffects,
  RPADefinitionEffects,
  RuleEffects,
]

export * from './auth.effects'
export * from './available-definition.effects'
export * from './connection.effects'
export * from './integration-application.effects'
export * from './integration-customer.effects'
export * from './job-history.effects'
export * from './log.effects'
export * from './message-history.effects'
export * from './rpa-definition.effects'
export * from './rule.effects'
