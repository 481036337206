import { Pipe, PipeTransform } from '@angular/core'
import {
  FhirClientType,
  HL7ConnectionDirection,
  HL7ConnectionStatus,
  IntegrationConnection,
  IntegrationConnectionType,
} from 'app/models'
import { ConnectionSelectors } from 'app/store'
import * as moment from 'moment-timezone'
import { filter, map } from 'rxjs/operators'

// TODO cover with tests

@Pipe({
  name: 'canStartConnection',
})
export class CanStartConnectionPipe implements PipeTransform {

  transform(value: IntegrationConnection): boolean {
    if (!value) {
      return false
    }

    return value.connectionType === IntegrationConnectionType.HL7 &&
      (value.hl7ConnectionStatus === HL7ConnectionStatus.CLOSED || value.hl7ConnectionStatus === HL7ConnectionStatus.FAILED)
  }
}

@Pipe({
  name: 'canStopConnection',
})
export class CanStopConnectionPipe implements PipeTransform {

  transform(value: IntegrationConnection): boolean {
    if (!value) {
      return false
    }

    return value.connectionType === IntegrationConnectionType.HL7 &&
      value.hl7ConnectionStatus !== HL7ConnectionStatus.CLOSED &&
      value.hl7ConnectionStatus !== HL7ConnectionStatus.FAILED
  }
}

@Pipe({
  name: 'connectionIsStopped',
})
export class ConnectionIsStoppedPipe implements PipeTransform {

  transform(value: IntegrationConnection): boolean {
    if (!value) {
      return false
    }

    return value.connectionType !== IntegrationConnectionType.HL7 ||
      value.hl7ConnectionStatus === HL7ConnectionStatus.CLOSED ||
      value.hl7ConnectionStatus === HL7ConnectionStatus.FAILED
  }
}

@Pipe({
  name: 'canSendMessage',
})
export class CanSendMessagePipe implements PipeTransform {

  transform(value: IntegrationConnection): boolean {
    if (!value) {
      return false
    }

    return value.connectionType === IntegrationConnectionType.HL7 && value.hl7ConnectionStatus === HL7ConnectionStatus.RUNNING
  }
}

@Pipe({
  name: 'lastMessageSentAt',
})
export class LastMessageSentAt implements PipeTransform {

  transform(value: IntegrationConnection): string {
    if (!value) {
      return
    }

    return value.lastMessageTimestamp ? moment.utc(value.lastMessageTimestamp).fromNow() : null
  }
}

@Pipe({
  name: 'connectionStatusColor',
})
export class ConnectionStatusColorPipe implements PipeTransform {

  transform(value: IntegrationConnection) {
    if (!value || value.connectionType !== IntegrationConnectionType.HL7) {
      return
    }

    switch (value.hl7ConnectionStatus) {
      case HL7ConnectionStatus.CLOSED:
      case HL7ConnectionStatus.FAILED:
        return 'warn-color'
      case HL7ConnectionStatus.RUNNING:
        return 'primary-color'
      default:
        return
    }
  }
}

@Pipe({
  name: 'connectionStatusText',
})
export class ConnectionStatusTextPipe implements PipeTransform {
  transform(value: IntegrationConnection) {
    if (!value) {
      return
    }

    if (value.connectionType === IntegrationConnectionType.HL7) {
      return value.hl7ConnectionStatus
    }

    return '-'
  }
}

@Pipe({
  name: 'connectionTypeText',
})
export class ConnectionTypeTextPipe implements PipeTransform {
  transform(value: IntegrationConnection) {
    if (!value) {
      return
    }

    if (value.connectionType === IntegrationConnectionType.FHIR) {
      return 'FHIR'
    }

    if (value.connectionType === IntegrationConnectionType.RPA) {
      return 'RPA'
    }

    switch (value.hl7ConnectionDirection) {
      case HL7ConnectionDirection.BI:
        return 'HL7 Bidirectional'
      case HL7ConnectionDirection.OUT:
        return 'HL7 Outgoing'
      case HL7ConnectionDirection.IN:
        return 'HL7 Incoming'
      case HL7ConnectionDirection.SFTP_IN:
        return 'HL7 SFTP Incoming'
      default:
        return '-'
    }
  }
}

@Pipe({
  name: 'copyIncomingTo',
})
export class CopyIncomingToPipe implements PipeTransform {
  constructor(private connectionSelectors: ConnectionSelectors) {
  }

  transform(value: IntegrationConnection) {
    return this.connectionSelectors.connections$.pipe(
      filter(Boolean),
      map((connections) => connections
        .filter((connection) => value.copyIncomingTo?.includes(connection.id))),
    )
  }
}

@Pipe({
  name: 'fhirClientType',
})
export class FhirClientTypePipe implements PipeTransform {
  constructor() {
  }

  transform(value: IntegrationConnection | null) {
    switch (value?.fhirClientType) {
      case FhirClientType.Epic:
        return 'Epic'
      case FhirClientType.EpicSandbox:
        return 'Epic Sandbox'
      case FhirClientType.Cerner:
        return 'Cerner'
      case FhirClientType.CernerOpenSandbox:
        return 'Cerner Open Sandbox'
      default:
        return ''
    }
  }
}
