import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { IntegrationJob } from 'app/models'

import { JobHistoryActions } from '../actions'

export interface JobHistoryState extends EntityState<IntegrationJob>{
  loading: boolean
  pageSize: number
  lastEvaluatedKey: string | null
}

const adapter: EntityAdapter<IntegrationJob> = createEntityAdapter<IntegrationJob>()

const initialState: JobHistoryState = adapter.getInitialState({
  loading: false,
  pageSize: 20,
  lastEvaluatedKey: null,
})

const jobHistoryReducer = createReducer(
  initialState,
  on(
    JobHistoryActions.loadJobs,
    JobHistoryActions.createJob,
    (state) => ({
      ...state,
      loading: true,
    })),
  on(JobHistoryActions.loadJobsSuccess, (state, { data, lastEvaluatedKey }) => {
    return adapter.addMany(data, { ...state, loading: false, lastEvaluatedKey })
  }),
  on(
    JobHistoryActions.loadJobsFailure,
    JobHistoryActions.createJobFailure,
    JobHistoryActions.createJobSuccess,
    (state) => ({
      ...state,
      loading: false,
    })),
  on(JobHistoryActions.pageUpdate, (state, { pageSize }) => {
    if (state.pageSize !== pageSize) {
      return {
        ...state,
        pageSize,
      }
    }

    return state
  }),
  on(JobHistoryActions.reset, (state) => adapter.removeAll({ ...state, lastEvaluatedKey: null })),
  on(JobHistoryActions.refresh, (state) => adapter.removeAll({ ...state, lastEvaluatedKey: null, loading: true })),
)

export function reducer(state: JobHistoryState | undefined, action: Action) {
  return jobHistoryReducer(state, action)
}

const {
  selectAll,
} = adapter.getSelectors()

export const selectAllJobs = selectAll
