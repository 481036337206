import { Injectable } from '@angular/core'
import { Amplify, Auth } from 'aws-amplify'
import { BehaviorSubject, from } from 'rxjs'

import { environment } from '../../../environments'

export interface IUser {
  email: string;
  password: string;
  showPassword: boolean;
  code: string;
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  authenticationSubject: BehaviorSubject<any>

  constructor() {
    Amplify.configure({
      Auth: environment.cognito,
    })

    this.authenticationSubject = new BehaviorSubject<boolean>(false)
  }

  public signIn(user: IUser) {
    return from(Auth.signIn(user.email, user.password))
  }

  public signOut(): Promise<any> {
    return Auth.signOut()
      .then(() => {
        this.authenticationSubject.next(false)
      })
  }

  public isAuthenticated(): Promise<boolean> {
    if (this.authenticationSubject.value) {
      return Promise.resolve(true)
    } else {
      return this.getUser()
        .then((user: any) => {
          if (user) {
            return true
          } else {
            return false
          }
        }).catch(() => {
          return false
        })
    }
  }

  public getUser(): Promise<any> {
    return Auth.currentUserInfo()
  }
}
