import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { templateRuleConnectionId, TransformationRule } from 'app/models'

import { RuleActions } from '../actions'
import { AppState } from '../reducers/index'

@Injectable({
  providedIn: 'root',
})
export class RuleDispatchers {
  constructor(private store: Store<AppState>) {}

  loadRules(connectionId: string) {
    this.store.dispatch(RuleActions.loadRules({ connectionId }))
  }

  loadDefaultRules() {
    this.store.dispatch(RuleActions.loadRules({ connectionId: templateRuleConnectionId }))
  }

  createRule(rule: Partial<TransformationRule>) {
    this.store.dispatch(RuleActions.createRule({ rule }))
  }

  updateRule(rule: TransformationRule) {
    this.store.dispatch(RuleActions.updateRule({ rule }))
  }

  deleteRule(rule: TransformationRule) {
    this.store.dispatch(RuleActions.deleteRule({ rule }))
  }

  reset() {
    this.store.dispatch(RuleActions.reset())
  }
}
