import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import { MessageHistoryActions } from '../actions'
import { AppState } from '../reducers/index'

@Injectable({
  providedIn: 'root',
})
export class MessageHistoryDispatchers {
  constructor(private store: Store<AppState>) {}

  load() {
    this.store.dispatch(MessageHistoryActions.loadMessages())
  }

  pageSizeUpdate(pageSize: number, expectedTotal: number) {
    this.store.dispatch(MessageHistoryActions.pageUpdate({ pageSize, expectedTotal }))
  }

  reset() {
    this.store.dispatch(MessageHistoryActions.reset())
  }

  refresh() {
    this.store.dispatch(MessageHistoryActions.refresh())
  }
}
