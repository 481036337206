import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { IntegrationLog } from 'app/models'
import { LogDispatchers, LogSelectors } from 'app/store'
import { orderBy } from 'lodash'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'app-logs',
  templateUrl: './logs-table.component.html',
  styleUrls: ['./logs-table.component.scss'],
})
export class LogsTableComponent implements OnInit, OnDestroy {
  @Input() showConnectionId = true

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  loading$: Observable<boolean>

  dataSource: MatTableDataSource<IntegrationLog>
  displayedColumns = ['source', 'message', 'createdAt']

  private onDestroy$: Subject<void> = new Subject<void>()

  constructor(
    private logDispatchers: LogDispatchers,
    private logSelectors: LogSelectors,
  ) { }

  ngOnInit() {
    if (this.showConnectionId) {
      this.displayedColumns.splice(2, 0, 'connectionId')
    }

    this.loading$ = this.logSelectors.loading$

    // todo refactor with observable data source
    this.logSelectors.logsWithMeta$
      .pipe(
        takeUntil(this.onDestroy$),
      )
      .subscribe((value) => {
        this.dataSource = new MatTableDataSource(orderBy(value.logs, ['logId'], ['desc', 'asc']))
        this.dataSource.paginator = this.paginator

        // @Override
        // Since we don't know the total number of records, the next line ensures we allow future pagination
        // if there are other records available
        this.paginator.hasNextPage = () => {
          if (this.paginator.getNumberOfPages() - 1 === this.paginator.pageIndex) {
            return value.hasNext
          } else {
            return true
          }
        }

        this.dataSource.sort = this.sort
      })

    this.logDispatchers.load()
  }

  ngOnDestroy() {
    this.logDispatchers.reset()
    this.onDestroy$.next()
    this.onDestroy$.complete()
  }

  applyFilter(filterValue: string) {
    if (!this.dataSource) {
      return
    }

    filterValue = filterValue.trim() // Remove whitespace
    filterValue = filterValue.toLowerCase() // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue
  }

  onPage(pageEvent: PageEvent) {
    this.logDispatchers.pageSizeUpdate(pageEvent.pageSize, (pageEvent.pageIndex + 1) * pageEvent.pageSize)
  }

  refresh() {
    this.logDispatchers.refresh()
  }
}
