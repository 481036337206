import { alarmThresholds } from '@integrations/shared'
import { createAction, props } from '@ngrx/store'
import { IntegrationConnection } from 'app/models'

enum ConnectionActionTypes {
  LOAD_ALL = '[Connections] Load All',
  LOAD_ALL_SUCCESS = '[Connections] Load All Success',
  LOAD_ALL_FAILURE = '[Connections] Load All Failure',

  LOAD = '[Connections] Load',
  LOAD_SUCCESS = '[Connections] Load Success',
  LOAD_FAILURE = '[Connections] Load Failure',

  RESET = '[Connections] Reset',

  CREATE = '[Connections] Create',
  CREATE_SUCCESS = '[Connections] Create Success',
  CREATE_FAILURE = '[Connections] Create Failure',

  UPDATE = '[Connections] Update',
  UPDATE_SUCCESS = '[Connections] Update Success',
  UPDATE_FAILURE = '[Connections] Update Failure',

  DELETE = '[Connections] Delete',
  DELETE_SUCCESS = '[Connections] Delete Success',
  DELETE_FAILURE = '[Connections] Delete Failure',

  START = '[Connections] Start',
  START_SUCCESS = '[Connections] Start Success',
  START_FAILURE = '[Connections] Start Failure',

  STOP = '[Connections] Stop',
  STOP_SUCCESS = '[Connections] Stop Success',
  STOP_FAILURE = '[Connections] Stop Failure',

  SEND_MESSAGE = '[Connections] Send Message',
  SEND_MESSAGE_SUCCESS = '[Connections] Send Message Success',
  SEND_MESSAGE_FAILURE = '[Connections] Send Message Failure',

  CREATE_ALARMS = '[Connections] Create Alarms',
  DELETE_ALARMS = '[Connections] Delete Alarms',

  LOAD_HL7_IP = '[Connections] Load HL7 IP',
  LOAD_HL7_IP_SUCCESS = '[Connections] Load HL7 IP Success',
}

const createConnectionsAction = (actionType: string) =>
  createAction(actionType, props<{ connections: IntegrationConnection[] }>())

const createConnectionAction = (actionType: string) =>
  createAction(actionType, props<{ connection: IntegrationConnection }>())

const simpleConnectionAction = (actionType: string) =>
  createAction(actionType, props<{ id: string }>())

const createConnectionErrorAction = (actionType: string) =>
  createAction(actionType, props<{ error: string }>())

export const loadAllConnections = createAction(ConnectionActionTypes.LOAD_ALL)
export const loadAllConnectionsSuccess = createConnectionsAction(ConnectionActionTypes.LOAD_ALL_SUCCESS)
export const loadAllConnectionsFailure = createConnectionErrorAction(ConnectionActionTypes.LOAD_ALL_FAILURE)

export const loadConnection = simpleConnectionAction(ConnectionActionTypes.LOAD)
export const loadConnectionSuccess = createConnectionAction(ConnectionActionTypes.LOAD_SUCCESS)
export const loadConnectionFailure = createConnectionErrorAction(ConnectionActionTypes.LOAD_FAILURE)

export const resetConnection = createAction(ConnectionActionTypes.RESET)

export const createConnection = createAction(ConnectionActionTypes.CREATE,
  props<{ connection: Partial<IntegrationConnection> }>())
export const createConnectionSuccess = createConnectionAction(ConnectionActionTypes.CREATE_SUCCESS)
export const createConnectionFailure = createConnectionErrorAction(ConnectionActionTypes.CREATE_FAILURE)

export const updateConnection = createConnectionAction(ConnectionActionTypes.UPDATE)
export const updateConnectionSuccess = createConnectionAction(ConnectionActionTypes.UPDATE_SUCCESS)
export const updateConnectionFailure = createConnectionErrorAction(ConnectionActionTypes.UPDATE_FAILURE)

export const deleteConnection = createConnectionAction(ConnectionActionTypes.DELETE)
export const deleteConnectionSuccess = createConnectionAction(ConnectionActionTypes.DELETE_SUCCESS)
export const deleteConnectionFailure = createConnectionErrorAction(ConnectionActionTypes.DELETE_FAILURE)

export const startConnection = simpleConnectionAction(ConnectionActionTypes.START)
export const startConnectionSuccess = simpleConnectionAction(ConnectionActionTypes.START_SUCCESS)
export const startConnectionFailure = createConnectionErrorAction(ConnectionActionTypes.START_FAILURE)

export const stopConnection = simpleConnectionAction(ConnectionActionTypes.STOP)
export const stopConnectionSuccess = simpleConnectionAction(ConnectionActionTypes.STOP_SUCCESS)
export const stopConnectionFailure = createConnectionErrorAction(ConnectionActionTypes.STOP_FAILURE)

export const sendMessage = createAction(ConnectionActionTypes.SEND_MESSAGE,
  props<{ message: string, controlId: string, connection: IntegrationConnection }>())

export const sendMessageSuccess = createAction(ConnectionActionTypes.SEND_MESSAGE_SUCCESS,
  props<{ connection: IntegrationConnection }>())
export const sendMessageFailure = createAction(ConnectionActionTypes.SEND_MESSAGE_FAILURE)

export const createAlarms = createAction(ConnectionActionTypes.CREATE_ALARMS, props<{ connectionId: string, thresholds: alarmThresholds }>())
export const deleteAlarms = createAction(ConnectionActionTypes.DELETE_ALARMS, props<{ connectionId: string }>())

export const loadHL7ServerIp = createAction(ConnectionActionTypes.LOAD_HL7_IP)
export const loadHL7ServerIpSuccess = createAction(ConnectionActionTypes.LOAD_HL7_IP_SUCCESS, props<{ hl7ServerIp: string }>())
