import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import { AvailableDefinitionService } from '../../core/services'
import { AvailableDefinitionActions, JobHistoryActions } from '../actions'

@Injectable()
export class AvailableDefinitionEffects {
  load$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AvailableDefinitionActions.loadAll),
        switchMap(() => {
          return this.availableDefinitionService.getAll().pipe(
            map((definitions) => {
              return AvailableDefinitionActions.loadAllSuccess({ definitions })
            }),
            catchError(() => {
              return of(JobHistoryActions.loadJobsFailure({ error: 'Cannot load available definitions' }))
            }),
          )
        }),
      ),
  )

  constructor(
    private actions$: Actions,
    private availableDefinitionService: AvailableDefinitionService,
  ) {}
}
