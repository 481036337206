import { createAction, props } from '@ngrx/store'
import { IntegrationApplication } from 'app/models'

enum IntegrationApplicationActionTypes {
  LOAD_ALL = '[Integration Applications] Load All',
  LOAD_ALL_SUCCESS = '[Integration Applications] Load All Success',
  LOAD_ALL_FAILURE = '[Integration Applications] Load All Failure',

  LOAD = '[Integration Applications] Load',
  LOAD_SUCCESS = '[Integration Applications] Load Success',
  LOAD_FAILURE = '[Integration Applications] Load Failure',

  CREATE = '[Integration Applications] Create',
  CREATE_SUCCESS = '[Integration Applications] Create Success',
  CREATE_FAILURE = '[Integration Applications] Create Failure',

  UPDATE = '[Integration Applications] Update',
  UPDATE_SUCCESS = '[Integration Applications] Update Success',
  UPDATE_FAILURE = '[Integration Applications] Update Failure',

  DELETE = '[Integration Applications] Delete',
  DELETE_SUCCESS = '[Integration Applications] Delete Success',
  DELETE_FAILURE = '[Integration Applications] Delete Failure',
}

const createApplicationsAction = (actionType: string) =>
  createAction(actionType, props<{ applications: IntegrationApplication[] }>())

const createApplicationAction = (actionType: string) =>
  createAction(actionType, props<{ application: IntegrationApplication }>())

const createApplicationErrorAction = (actionType: string) =>
  createAction(actionType, props<{ error: string }>())

export const loadAllApplications = createAction(IntegrationApplicationActionTypes.LOAD_ALL)
export const loadAllApplicationsSuccess = createApplicationsAction(IntegrationApplicationActionTypes.LOAD_ALL_SUCCESS)
export const loadAllApplicationsFailure = createApplicationErrorAction(IntegrationApplicationActionTypes.LOAD_ALL_FAILURE)

export const loadApplication = createAction(IntegrationApplicationActionTypes.LOAD, props<{ id: string }>())
export const loadApplicationSuccess = createApplicationAction(IntegrationApplicationActionTypes.LOAD_SUCCESS)
export const loadApplicationFailure = createApplicationErrorAction(IntegrationApplicationActionTypes.LOAD_FAILURE)

export const createApplication = createAction(IntegrationApplicationActionTypes.CREATE, props<Partial<IntegrationApplication>>())
export const createApplicationSuccess = createApplicationAction(IntegrationApplicationActionTypes.CREATE_SUCCESS)
export const createApplicationFailure = createApplicationErrorAction(IntegrationApplicationActionTypes.CREATE_FAILURE)

export const updateApplication = createApplicationAction(IntegrationApplicationActionTypes.UPDATE)
export const updateApplicationSuccess = createApplicationAction(IntegrationApplicationActionTypes.UPDATE_SUCCESS)
export const updateApplicationFailure = createApplicationErrorAction(IntegrationApplicationActionTypes.UPDATE_FAILURE)

export const deleteApplication = createApplicationAction(IntegrationApplicationActionTypes.DELETE)
export const deleteApplicationSuccess = createApplicationAction(IntegrationApplicationActionTypes.DELETE_SUCCESS)
export const deleteApplicationFailure = createApplicationErrorAction(IntegrationApplicationActionTypes.DELETE_FAILURE)
