import { Component, OnInit, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatButton } from '@angular/material/button'
import { MatProgressBar } from '@angular/material/progress-bar'
import { Observable } from 'rxjs'

import { AuthDispatchers, AuthSelectors } from '../../store'

@Component({
  selector: 'app-admin-login',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.scss'],
})
export class LogInComponent implements OnInit {
  @ViewChild(MatProgressBar) progressBar: MatProgressBar
  @ViewChild(MatButton, { static: true }) submitButton: MatButton

  loading$: Observable<boolean>

  signInForm: FormGroup
  errorMsg: string

  errorMessage: string | null

  constructor(
    private authDispatchers: AuthDispatchers,
    private authSelectors: AuthSelectors,
  ) {
    this.loading$ = this.authSelectors.loading$
  }

  async ngOnInit() {
    this.signInForm = new FormGroup({
      email: new FormControl<string>('', [Validators.required, Validators.email]),
      password: new FormControl<string>('', [Validators.required, Validators.minLength(7)]),
    })
  }

  onSubmit() {
    if (this.signInForm.valid) {
      const signInData = this.signInForm.value
      this.authDispatchers.logIn(signInData.email, signInData.password)
    }
  }
}
