import { Injectable } from '@angular/core'
import { alarmThresholds } from '@integrations/shared'
import { IntegrationConnection } from 'app/models'

import { RestService } from './rest.service'

@Injectable()
export class ConnectionService {

  constructor(private restService: RestService) {}

  getAll() {
    const route = '/connection'

    return this.restService.get<IntegrationConnection[]>(route)
  }

  get(id: string) {
    const route = `/connection/${id}`

    return this.restService.get<IntegrationConnection>(route)
  }

  create(data: Partial<IntegrationConnection>) {
    const route = `/connection`

    return this.restService.post<IntegrationConnection>(route, data)
  }

  update(data: Partial<IntegrationConnection>) {
    const route = `/connection/${data.id}`

    return this.restService.put<IntegrationConnection>(route, data)
  }

  delete(id: string) {
    const route = `/connection/${id}`
    return this.restService.delete(route)
  }

  startConnection(id: string) {
    const route = `/connection/${id}/action`
    return this.restService.post(route, null, { queryStringParameters: { actionType: 'start' } })
  }

  stopConnection(id: string) {
    const route = `/connection/${id}/action`
    return this.restService.post(route, null, { queryStringParameters: { actionType: 'stop' } })
  }

  sendMessage(connectionId: string, message: string, headerOptions: { controlId: string, signature: string }) {
    const route = `/connection/${connectionId}/message`
    const headers = {
      'Message-Control-Id': headerOptions.controlId,
      'MMX-Signature': headerOptions.signature,
    }

    return this.restService.post(route, { message }, { headers })
  }

  createAlarms(id: string, thresholds: alarmThresholds) {
    const route = `/connection/${id}/action`
    return this.restService.post(route, thresholds, { queryStringParameters: { actionType: 'create-alarms' } })
  }

  deleteAlarms(id: string) {
    const route = `/connection/${id}/action`
    return this.restService.post(route, null, { queryStringParameters: { actionType: 'delete-alarms' } })
  }

  getHL7ServerIP() {
    return this.restService.get<{ ip: string }>('/hl7-discovery')
  }
}
