import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { RPADefinitionService } from 'app/core/services'
import { of } from 'rxjs'
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators'

import * as RPADefinitionActions from '../actions/rpa-definition.actions'
import { ConnectionSelectors, RPADefinitionSelectors } from '../selectors'

@Injectable()
export class RPADefinitionEffects {
  loadDefinitions$ = createEffect(() => this.actions$.pipe(
    ofType(RPADefinitionActions.loadDefinitions),
    switchMap(({ connectionId }) => this.definitionService.getDefinitions(connectionId).pipe(
      map((response) => RPADefinitionActions.loadDefinitionsSuccess(response)),
      catchError(() => {
        this.snackBar.open('Failed to load RPA definitions', 'close', { duration: 3000 })
        return of(RPADefinitionActions.loadDefinitionsFailure({ error: 'Load definitions failure' }))
      })),
    )),
  )

  createDefinition$ = createEffect(() => this.actions$.pipe(
    ofType(RPADefinitionActions.createDefinition),
    switchMap(({ definition }) => this.definitionService.createDefinition(definition).pipe(
      map((newDefinition) => {
        this.dialog.closeAll()
        return RPADefinitionActions.createDefinitionSuccess({ definition: newDefinition })
      }),
      catchError(() => {
        this.snackBar.open('Failed to create RPA definition', 'close', { duration: 3000 })
        return of(RPADefinitionActions.createDefinitionFailure({ error: 'Create definition failure' }))
      }),
    )),
  ))

  updateDefinition$ = createEffect(() => this.actions$.pipe(
    ofType(RPADefinitionActions.updateDefinition),
    switchMap(({ definition }) => this.definitionService.updateDefinition(definition).pipe(
      map((definition) => {
        this.dialog.closeAll()
        return RPADefinitionActions.updateDefinitionSuccess({ definition })
      }),
      catchError(() => {
        this.snackBar.open('Failed to update RPA definition', 'close', { duration: 3000 })
        return of(RPADefinitionActions.updateDefinitionFailure({ error: 'Update definition failure' }))
      }),
    )),
  ))

  deleteDefinition$ = createEffect(() => this.actions$.pipe(
    ofType(RPADefinitionActions.deleteDefinition),
    switchMap(({ definition }) => this.definitionService.deleteDefinition(definition).pipe(
      map(() => {
        return RPADefinitionActions.deleteDefinitionSuccess({ definition })
      }),
      catchError(() => {
        this.snackBar.open('Failed to delete RPA definition', 'close', { duration: 3000 })
        return of(RPADefinitionActions.deleteDefinitionFailure({ error: 'Delete definition failure' }))
      }),
    )),
  ))

  pageUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RPADefinitionActions.pageUpdate),
        withLatestFrom(
          this.definitionSelectors.definitionsWithMeta$,
          this.connectionSelectors.currentConnection$,
        ),
        filter(([{ expectedTotal }, state]) => expectedTotal > state.definitions.length && state.hasNext),
        map(([, , connection]) => RPADefinitionActions.loadDefinitions({ connectionId: connection.id })),
      ),
  )

  constructor(
    private actions$: Actions,
    private definitionService: RPADefinitionService,
    private definitionSelectors: RPADefinitionSelectors,
    private connectionSelectors: ConnectionSelectors,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {}
}
