import { Injectable } from '@angular/core'
import { IntegrationApplication } from 'app/models'
import { Observable } from 'rxjs'

import { RestService } from './rest.service'

@Injectable()
export class IntegrationApplicationService {

  constructor(private restService: RestService) {}

  getAll(): Observable<IntegrationApplication[]> {
    const route = '/client'

    return this.restService.get<IntegrationApplication[]>(route)
  }

  get(id: string): Observable<IntegrationApplication> {
    const route = `/client/${id}`

    return this.restService.get<IntegrationApplication>(route)
  }

  create(name: string): Observable<IntegrationApplication> {
    const route = `/client`
    return this.restService.post<IntegrationApplication>(route, { name })
  }

  update(data: IntegrationApplication) {
    const route = `/client/${data.id}`
    return this.restService.put<IntegrationApplication>(route, data)
  }

  delete(id: string) {
    return this.restService.delete(`/client/${id}`)
  }
}
