import { createAction, props } from '@ngrx/store'
import { IntegrationCustomer } from 'app/models'

enum CustomerActionTypes {
  LOAD_ALL = '[Integration Customers] Load All',
  LOAD_ALL_SUCCESS = '[Integration Customers] Load All Success',
  LOAD_ALL_FAILURE = '[Integration Customers] Load All Failure',

  LOAD = '[Integration Customers] Load',
  LOAD_SUCCESS = '[Integration Customers] Load Success',
  LOAD_FAILURE = '[Integration Customers] Load Failure',

  CREATE = '[Integration Customers] Create',
  CREATE_SUCCESS = '[Integration Customers] Create Success',
  CREATE_FAILURE = '[Integration Customers] Create Failure',

  UPDATE = '[Integration Customers] Update',
  UPDATE_SUCCESS = '[Integration Customers] Update Success',
  UPDATE_FAILURE = '[Integration Customers] Update Failure',

  DELETE = '[Integration Customers] Delete',
  DELETE_SUCCESS = '[Integration Customers] Delete Success',
  DELETE_FAILURE = '[Integration Customers] Delete Failure',
}

const createCustomersAction = (actionType: string) =>
  createAction(actionType, props<{ customers: IntegrationCustomer[] }>())

const createCustomerAction = (actionType: string) =>
  createAction(actionType, props<{ customer: IntegrationCustomer }>())

const createCustomersErrorAction = (actionType: string) =>
  createAction(actionType, props<{ error: string }>())

export const loadAllCustomers = createAction(CustomerActionTypes.LOAD_ALL)
export const loadAllCustomersSuccess = createCustomersAction(CustomerActionTypes.LOAD_ALL_SUCCESS)
export const loadAllCustomersFailure = createCustomersErrorAction(CustomerActionTypes.LOAD_ALL_FAILURE)

export const loadCustomer = createAction(CustomerActionTypes.LOAD, props<{ id: string }>())
export const loadCustomerSuccess = createCustomerAction(CustomerActionTypes.LOAD_SUCCESS)
export const loadCustomerFailure = createCustomersErrorAction(CustomerActionTypes.LOAD_FAILURE)

export const createCustomer = createAction(CustomerActionTypes.CREATE, props<Partial<IntegrationCustomer>>())
export const createCustomerSuccess = createCustomerAction(CustomerActionTypes.CREATE_SUCCESS)
export const createCustomerFailure = createCustomersErrorAction(CustomerActionTypes.CREATE_FAILURE)

export const updateCustomer = createCustomerAction(CustomerActionTypes.UPDATE)
export const updateCustomerSuccess = createCustomerAction(CustomerActionTypes.UPDATE_SUCCESS)
export const updateCustomerFailure = createCustomersErrorAction(CustomerActionTypes.UPDATE_FAILURE)

export const deleteCustomer = createCustomerAction(CustomerActionTypes.DELETE)
export const deleteCustomerSuccess = createCustomerAction(CustomerActionTypes.DELETE_SUCCESS)
export const deleteCustomerFailure = createCustomersErrorAction(CustomerActionTypes.DELETE_FAILURE)
