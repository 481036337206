import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { LogInComponent } from './components/log-in/log-in.component'
import { FullComponent } from './core/layouts/full/full.component'
import { AuthGuardService as AuthGuard } from './core/services/auth-guard.service'
import { LogsTableComponent } from './shared/components/logs-table'

const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'customers',
        loadChildren: () => import('./modules/customers/customers.module').then((m) => m.CustomersModule),
      },
      {
        path: 'applications',
        loadChildren: () => import('./modules/applications/applications.module').then((m) => m.ApplicationsModule),
      },
      {
        path: 'connections',
        loadChildren: () => import('./modules/connections/connections.module').then((m) => m.ConnectionsModule),
      },
      {
        path: 'logs',
        component: LogsTableComponent,
      },
      {
        path: 'rules',
        loadChildren: () => import('./modules/rules').then((m) => m.RulesModule),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/applications',
      },
    ],
  },
  {
    path: 'log-in',
    component: LogInComponent,
  },
  {
    path: '**',
    redirectTo: '/applications',
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'top',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {

}
