import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'

import { AvailableSoftware } from '../../models'
import { AvailableDefinitionActions } from '../actions'

export interface AvailableDefinitionState extends EntityState<AvailableSoftware>{
  loading: boolean
}

const adapter: EntityAdapter<AvailableSoftware> = createEntityAdapter<AvailableSoftware>()

const initialState: AvailableDefinitionState = adapter.getInitialState({
  loading: false,
})

const availableDefinitionReducer = createReducer(
  initialState,
  on(
    AvailableDefinitionActions.loadAll,
    (state) => ({
      ...state,
      loading: true,
    })),
  on(AvailableDefinitionActions.loadAllSuccess, (state, { definitions }) => {
    return adapter.setAll(definitions, { ...state, loading: false })
  }),
  on(AvailableDefinitionActions.loadAllFailure, (state) => ({
    ...state,
    loading: false,
  })),
)

export function reducer(state: AvailableDefinitionState | undefined, action: Action) {
  return availableDefinitionReducer(state, action)
}

const {
  selectAll,
} = adapter.getSelectors()

export const selectAllAvailableDefinitions = selectAll
