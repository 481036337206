import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { IntegrationJob } from 'app/models'

import { JobHistoryActions } from '../actions'
import { AppState } from '../reducers/index'

@Injectable({
  providedIn: 'root',
})
export class JobHistoryDispatchers {
  constructor(private store: Store<AppState>) {}

  create(job: Partial<IntegrationJob>) {
    this.store.dispatch(JobHistoryActions.createJob({ job }))
  }

  loadHistory() {
    this.store.dispatch(JobHistoryActions.loadJobs())
  }

  pageSizeUpdate(pageSize: number, expectedTotal: number) {
    this.store.dispatch(JobHistoryActions.pageUpdate({ pageSize, expectedTotal }))
  }

  reset() {
    this.store.dispatch(JobHistoryActions.reset())
  }

  refresh() {
    this.store.dispatch(JobHistoryActions.refresh())
  }
}
