import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { IntegrationConnection } from 'app/models'

import { ConnectionActions } from '../actions'

export interface ConnectionState extends EntityState<IntegrationConnection>{
  loading: boolean
  currentConnection: string | null
  hl7ServerIp: string
}

const adapter: EntityAdapter<IntegrationConnection> = createEntityAdapter<IntegrationConnection>()

const initialState: ConnectionState = adapter.getInitialState({
  loading: false,
  currentConnection: null,
  hl7ServerIp: '',
})

const connectionReducer = createReducer(
  initialState,
  on(
    ConnectionActions.loadAllConnections,
    ConnectionActions.startConnection,
    ConnectionActions.stopConnection,
    ConnectionActions.createConnection,
    ConnectionActions.updateConnection,
    ConnectionActions.deleteConnection,
    ConnectionActions.startConnectionSuccess,
    ConnectionActions.stopConnectionSuccess,
    (state) => ({
      ...state,
      loading: true,
    }),
  ),
  on(ConnectionActions.loadAllConnectionsSuccess, (state, { connections }) => {
    return adapter.setAll(connections, { ...state, loading: false })
  }),
  on(
    ConnectionActions.loadAllConnectionsFailure,
    ConnectionActions.loadConnectionFailure,
    ConnectionActions.startConnectionFailure,
    ConnectionActions.stopConnectionFailure,
    ConnectionActions.createConnectionFailure,
    ConnectionActions.updateConnectionFailure,
    ConnectionActions.deleteConnectionFailure,
    (state) => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    ConnectionActions.loadConnection,
    (state, { id }) => ({
      ...state,
      currentConnection: id,
      loading: true,
    }),
  ),
  on(
    ConnectionActions.loadConnectionSuccess,
    ConnectionActions.createConnectionSuccess,
    ConnectionActions.updateConnectionSuccess,
    (state, { connection }) => {
      return adapter.upsertOne(connection, { ...state, loading: false })
    },
  ),
  on(ConnectionActions.resetConnection, (state) => ({
    ...state,
    currentConnection: undefined,
    loading: false,
  })),
  on(ConnectionActions.deleteConnectionSuccess, (state, { connection }) => {
    return adapter.removeOne(connection.id, { ...state, loading: false })
  }),
  on(ConnectionActions.loadHL7ServerIpSuccess, (state, { hl7ServerIp }) => ({
    ...state,
    hl7ServerIp,
  })),
)

export function reducer(state: ConnectionState | undefined, action: Action) {
  return connectionReducer(state, action)
}

const {
  selectEntities,
  selectAll,
} = adapter.getSelectors()

export const selectConnectionEntities = selectEntities
export const selectAllConnections = selectAll

