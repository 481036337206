import { DOCUMENT } from '@angular/common'
import { Component, Inject, OnDestroy, ViewEncapsulation } from '@angular/core'
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router'

@Component({
  selector: 'app-spinner',
  template: `<app-mmx-icon *ngIf="isSpinnerVisible"></app-mmx-icon>`,
  encapsulation: ViewEncapsulation.None,
})
export class SpinnerComponent implements OnDestroy {
  public isSpinnerVisible = true

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.router.events.subscribe({
      next: (event) => {
        if (event instanceof NavigationStart) {
          this.isSpinnerVisible = true
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.isSpinnerVisible = false
        }
      },
      error: () => {
        this.isSpinnerVisible = false
      },
    })
  }

  ngOnDestroy(): void {
    this.isSpinnerVisible = false
  }
}
