import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { IntegrationApplicationService } from 'app/core/services'
import { get } from 'lodash'
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import * as IntegrationApplicationActions from '../actions/integration-application.actions'

@Injectable()
export class IntegrationApplicationEffects {
  loadAll$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationApplicationActions.loadAllApplications),
        switchMap(() => {
          return this.integrationApplicationService.getAll().pipe(
            map((applications) => {
              return IntegrationApplicationActions.loadAllApplicationsSuccess({ applications })
            }),
            catchError(() => {
              return of(IntegrationApplicationActions.loadAllApplicationsFailure({ error: 'Cannot load all integration applications' }))
            }),
          )
        }),
      ),
    { useEffectsErrorHandler: false },
  )

  load$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationApplicationActions.loadApplication),
        switchMap((action) => {
          return this.integrationApplicationService.get(action.id).pipe(
            map((application) => {
              return IntegrationApplicationActions.loadApplicationSuccess({ application })
            }),
            catchError(() => {
              return of(IntegrationApplicationActions.loadApplicationFailure({ error: 'Cannot load application-dialog' }))
            }),
          )
        }),
      ),
    { useEffectsErrorHandler: false },
  )

  create$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationApplicationActions.createApplication),
        switchMap(({ name }) => {
          return this.integrationApplicationService.create(name).pipe(
            map((newApplication) => {
              this.dialog.closeAll()
              this.snackBar.open('Integration application created', 'close', { duration: 3000 })
              return IntegrationApplicationActions.createApplicationSuccess({ application: newApplication })
            }),
            catchError(() => {
              this.snackBar.open('Cannot create integration application', 'close', { duration: 3000 })
              return of(IntegrationApplicationActions.createApplicationFailure({ error: 'Cannot create integration application' }))
            }),
          )
        }),
      ),
    { useEffectsErrorHandler: false },
  )

  update$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationApplicationActions.updateApplication),
        map((action) => action.application),
        switchMap((application) => {
          return this.integrationApplicationService.update(application).pipe(
            map((application) => {
              this.dialog.closeAll()
              this.snackBar.open('Integration application updated', 'close', { duration: 3000 })
              return IntegrationApplicationActions.updateApplicationSuccess({ application })
            }),
            catchError((err: HttpErrorResponse) => {
              const message = get(err, 'error.errors[0].message', '')
              this.snackBar.open(`Cannot update application: ${message}`, 'close', { duration: 3000 })
              return of(IntegrationApplicationActions.updateApplicationFailure({ error: 'Cannot update application-dialog' }))
            }),
          )
        }),
      ),
    { useEffectsErrorHandler: false },
  )

  delete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationApplicationActions.deleteApplication),
        switchMap(({ application }) => {
          return this.integrationApplicationService.delete(application.id).pipe(
            map(() => {
              this.dialog.closeAll()
              this.snackBar.open('Application deleted', 'close', { duration: 3000 })
              return IntegrationApplicationActions.deleteApplicationSuccess({ application })
            }),
            catchError((err: HttpErrorResponse) => {
              const message = get(err, 'error.errors[0].message', '')
              this.snackBar.open(`Cannot delete Application: ${message}`, 'close', { duration: 3000 })
              return of(IntegrationApplicationActions.deleteApplicationFailure({ error: 'Cannot delete application' }))
            }),
          )
        }),
      ),
  )

  constructor(
    private actions$: Actions,
    private integrationApplicationService: IntegrationApplicationService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}
}
