<div class="user-profile">
  <div class="profile-img"><img src="assets/images/users/profile.png" alt="user"></div>
</div>

<mat-nav-list appAccordion>
  <mat-list-item appAccordionLink *ngFor="let menuitem of menuItems" routerLinkActive="selected"
                 group="{{menuitem.state}}">
    <a class="" appAccordionToggle [routerLink]="['/', menuitem.state]" *ngIf="menuitem.type === 'link'">
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name }}</span>
      <span fxFlex></span>
    </a>
  </mat-list-item>
</mat-nav-list>
