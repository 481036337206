import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { IntegrationCustomer } from 'app/models'

import { IntegrationCustomerActions } from '../actions'
import { AppState } from '../reducers/index'

@Injectable()
export class IntegrationCustomerDispatchers {
  constructor(private store: Store<AppState>) {}

  create(customerName: string) {
    this.store.dispatch(IntegrationCustomerActions.createCustomer({ name: customerName }))
  }

  update(customer: IntegrationCustomer) {
    this.store.dispatch(IntegrationCustomerActions.updateCustomer({ customer }))
  }

  load(id: string) {
    this.store.dispatch(IntegrationCustomerActions.loadCustomer({ id }))
  }

  loadAll() {
    this.store.dispatch(IntegrationCustomerActions.loadAllCustomers())
  }

  delete(customer: IntegrationCustomer) {
    this.store.dispatch(IntegrationCustomerActions.deleteCustomer({ customer }))
  }
}
