import { createAction, props } from '@ngrx/store'

enum AuthActionTypes {
  LOGIN = '[Auth] Login',
  LOGIN_SUCCESS = '[Auth] Login Success',
  LOGIN_FAILURE = '[Auth] Login Failure',

  LOGOUT = '[Auth] Logout',

  SESSION_EXPIRED = '[Auth] Session Expired',
}

const createAuthErrorAction = (actionType: string) =>
  createAction(actionType, props<{ error: string }>())

const createSessionExpiredAction = (actionType: string) =>
  createAction(actionType, props<{ message: string }>())

export const logIn = createAction(AuthActionTypes.LOGIN, props<{ email: string, password: string }>())
export const logInSuccess = createAction(AuthActionTypes.LOGIN_SUCCESS)
export const logInFailure = createAuthErrorAction(AuthActionTypes.LOGIN_FAILURE)

export const logOut = createAction(AuthActionTypes.LOGOUT)

export const sessionExpired = createSessionExpiredAction(AuthActionTypes.SESSION_EXPIRED)
