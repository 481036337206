import {
  AuthDispatchers,
  ConnectionDispatchers,
  IntegrationApplicationDispatchers,
  IntegrationCustomerDispatchers,
  LogDispatchers,
} from './dispatchers'
import {
  AuthSelectors,
  ConnectionSelectors,
  IntegrationApplicationSelectors,
  IntegrationCustomerSelectors,
  LogSelectors,
} from './selectors'

export * from './actions'
export * from './dispatchers'
export * from './effects'
export * from './reducers'
export * from './selectors'

export const dispatchers = [
  AuthDispatchers,
  IntegrationApplicationDispatchers,
  IntegrationCustomerDispatchers,
  ConnectionDispatchers,
  LogDispatchers,
]

export const selectors = [
  AuthSelectors,
  IntegrationApplicationSelectors,
  IntegrationCustomerSelectors,
  ConnectionSelectors,
  LogSelectors,
]
