import { createAction, props } from '@ngrx/store'

import { AvailableSoftware } from '../../models'

enum AvailableDefinitionActionTypes {
  LOAD = '[AvailableDefinition] Load',
  LOAD_SUCCESS = '[AvailableDefinition] Load Success',
  LOAD_FAILURE = '[AvailableDefinition] Load Failure',
}

const createAvailableDefinitionAction = (actionType: string) =>
  createAction(actionType, props<{ definitions: AvailableSoftware[] }>())

export const loadAll = createAction(AvailableDefinitionActionTypes.LOAD)
export const loadAllSuccess = createAvailableDefinitionAction(AvailableDefinitionActionTypes.LOAD_SUCCESS)
export const loadAllFailure = createAction(AvailableDefinitionActionTypes.LOAD_FAILURE, props<{ error: string }>())
