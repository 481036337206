import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { TransformationRule } from 'app/models'

import { RuleActions } from '../actions'

export interface RuleState extends EntityState<TransformationRule>{
  loading: boolean
}

const adapter: EntityAdapter<TransformationRule> = createEntityAdapter<TransformationRule>()

const initialState: RuleState = adapter.getInitialState({
  loading: false,
})

const ruleReducer = createReducer(
  initialState,
  on(
    RuleActions.loadRules,
    RuleActions.createRule,
    RuleActions.updateRule,
    RuleActions.deleteRule,
    (state) => ({
      ...state,
      loading: true,
    }),
  ),
  on(RuleActions.loadRulesSuccess, (state, { rules }) => adapter.upsertMany(rules, { ...state, loading: false })),
  on(
    RuleActions.loadRulesFailure,
    RuleActions.createRuleFailure,
    RuleActions.updateRuleFailure,
    RuleActions.deleteRuleFailure,
    (state) => ({
      ...state,
      loading: false,
    }),
  ),
  on(RuleActions.createRuleSuccess, (state, { rule }) => adapter.addOne(rule, { ...state, loading: false })),
  on(RuleActions.updateRuleSuccess, (state, { rule }) => adapter.upsertOne(rule, { ...state, loading: false })),
  on(RuleActions.deleteRuleSuccess, (state, { rule }) => adapter.removeOne(rule.id, { ...state, loading: false })),
  on(RuleActions.reset, (state) => adapter.removeAll({ ...state, loading: false })),
)

export function reducer(state: RuleState | undefined, action: Action) {
  return ruleReducer(state, action)
}

const {
  selectAll,
} = adapter.getSelectors()

export const selectAllRules = selectAll
