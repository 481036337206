import { Injectable } from '@angular/core'
import { createFeatureSelector, createSelector, Store } from '@ngrx/store'
import { IntegrationLog } from 'app/models'
import { combineLatestWith } from 'rxjs'
import { map } from 'rxjs/operators'

import { AppState } from '../'
import { LogState, selectAllLogs } from '../reducers/log.reducer'
import { getCurrentConnection } from './connection.selectors'

const getLogState = createFeatureSelector<LogState>('log')

const getLogs = createSelector(
  getLogState,
  selectAllLogs,
)

const getLoading = createSelector(
  getLogState,
  (state) => state.loading,
)

const getQueryParam = createSelector(
  getLogState,
  getCurrentConnection,
  (logState, currentConnection) => {
    const params: { pageSize: number, lastEvaluatedKey?: string, connectionId?: string } = { pageSize: logState.pageSize }

    if (logState.lastEvaluatedKey) {
      params.lastEvaluatedKey = logState.lastEvaluatedKey
    }

    if (currentConnection) {
      params.connectionId = currentConnection.id
    }

    return params
  },
)

const hasNext = createSelector(
  getLogState,
  (state) => !!state.lastEvaluatedKey,
)

@Injectable()
export class LogSelectors {
  logs$ = this.store.select(getLogs)
  loading$ = this.store.select(getLoading)
  queryParam$ = this.store.select(getQueryParam)
  logsWithMeta$ = this.logs$.pipe(
    combineLatestWith(this.store.select(hasNext)),
    map(([logs, hasNext]): { logs: IntegrationLog[], hasNext: boolean } => ({
      logs,
      hasNext,
    })),
  )
  constructor(private store: Store<AppState>) {}
}
