import { Injectable } from '@angular/core'
import { createFeatureSelector, createSelector, Store } from '@ngrx/store'

import { AppState } from '../'
import { AuthState } from '../reducers/auth.reducer'

const getAuthState = createFeatureSelector<AuthState>('auth')

const selectLoading = createSelector(
  getAuthState,
  (state: AuthState) => state.loading,
)

@Injectable()
export class AuthSelectors {
  loading$ = this.store.select(selectLoading)
  constructor(private store: Store<AppState>) {}
}
