import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { HistoryMessage } from 'app/models'

import { MessageHistoryActions } from '../actions'

export interface MessageHistoryState extends EntityState<HistoryMessage>{
  loading: boolean
  pageSize: number
  lastEvaluatedKey: string | null
}

const adapter: EntityAdapter<HistoryMessage> = createEntityAdapter<HistoryMessage>()

const initialState: MessageHistoryState = adapter.getInitialState({
  loading: false,
  pageSize: 20,
  lastEvaluatedKey: null,
})

const messageHistoryReducer = createReducer(
  initialState,
  on(
    MessageHistoryActions.loadMessages,
    (state) => ({
      ...state,
      loading: true,
    })),
  on(MessageHistoryActions.loadMessagesSuccess, (state, { data, lastEvaluatedKey }) => {
    return adapter.addMany(data, { ...state, loading: false, lastEvaluatedKey })
  }),
  on(MessageHistoryActions.loadMessagesFailure, (state) => ({
    ...state,
    loading: false,
  })),
  on(MessageHistoryActions.pageUpdate, (state, { pageSize }) => {
    if (state.pageSize !== pageSize) {
      return {
        ...state,
        pageSize,
      }
    }

    return state
  }),
  on(MessageHistoryActions.reset, (state) => adapter.removeAll({ ...state, lastEvaluatedKey: null })),
  on(MessageHistoryActions.refresh, (state) => adapter.removeAll({ ...state, lastEvaluatedKey: null, loading: true })),
)

export function reducer(state: MessageHistoryState | undefined, action: Action) {
  return messageHistoryReducer(state, action)
}

const {
  selectAll,
} = adapter.getSelectors()

export const selectAllMessages = selectAll
