import { createAction, props } from '@ngrx/store'
import { TransformationRule } from 'app/models'

export enum RuleActionTypes {
  LOAD = '[Rule] Load',
  LOAD_SUCCESS = '[Rule] Load Success',
  LOAD_FAILURE = '[Rule] Load Failure',

  CREATE = '[Rule] Create',
  CREATE_SUCCESS = '[Rule] Create Success',
  CREATE_FAILURE = '[Rule] Create Failure',

  UPDATE = '[Rule] Update',
  UPDATE_SUCCESS = '[Rule] Update Success',
  UPDATE_FAILURE = '[Rule] Update Failure',

  DELETE = '[Rule] Delete',
  DELETE_SUCCESS = '[Rule] Delete Success',
  DELETE_FAILURE = '[Rule] Delete Failure',

  RESET = '[Rule] Reset',
}

export const loadRules = createAction(RuleActionTypes.LOAD, props<{ connectionId: string }>())
export const loadRulesSuccess = createAction(RuleActionTypes.LOAD_SUCCESS, props<{ rules: TransformationRule[] }>())
export const loadRulesFailure = createAction(RuleActionTypes.LOAD_FAILURE, props<{ error: string }>())

export const createRule = createAction(RuleActionTypes.CREATE, props<{ rule: Partial<TransformationRule> }>())
export const createRuleSuccess = createAction(RuleActionTypes.CREATE_SUCCESS, props<{ rule: TransformationRule }>())
export const createRuleFailure = createAction(RuleActionTypes.CREATE_FAILURE, props<{ error: string }>())

export const updateRule = createAction(RuleActionTypes.UPDATE, props<{ rule: TransformationRule }>())
export const updateRuleSuccess = createAction(RuleActionTypes.UPDATE_SUCCESS, props<{ rule: TransformationRule }>())
export const updateRuleFailure = createAction(RuleActionTypes.UPDATE_FAILURE, props<{ error: string }>())

export const deleteRule = createAction(RuleActionTypes.DELETE, props<{ rule: TransformationRule }>())
export const deleteRuleSuccess = createAction(RuleActionTypes.DELETE_SUCCESS, props<{ rule: TransformationRule }>())
export const deleteRuleFailure = createAction(RuleActionTypes.DELETE_FAILURE, props<{ error: string }>())

export const reset = createAction(RuleActionTypes.RESET)
