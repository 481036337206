import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { RPADefinition } from 'app/models'

import { RPADefinitionActions } from '../actions'
import { AppState } from '../reducers/index'

@Injectable({
  providedIn: 'root',
})
export class RPADefinitionDispatchers {
  constructor(private store: Store<AppState>) {}

  loadDefinitions(connectionId: string) {
    this.store.dispatch(RPADefinitionActions.loadDefinitions({ connectionId }))
  }

  createDefinition(definition: Partial<RPADefinition>) {
    this.store.dispatch(RPADefinitionActions.createDefinition({ definition }))
  }

  updateDefinition(definition: RPADefinition) {
    this.store.dispatch(RPADefinitionActions.updateDefinition({ definition }))
  }

  deleteDefinition(definition: RPADefinition) {
    this.store.dispatch(RPADefinitionActions.deleteDefinition({ definition }))
  }

  pageSizeUpdate(pageSize: number, expectedTotal: number) {
    this.store.dispatch(RPADefinitionActions.pageUpdate({ pageSize, expectedTotal }))
  }

  reset() {
    this.store.dispatch(RPADefinitionActions.reset())
  }

  refresh() {
    this.store.dispatch(RPADefinitionActions.refresh())
  }
}
