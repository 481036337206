import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { IntegrationApplication } from 'app/models'

import { ConnectionActions, IntegrationApplicationActions } from '../actions'

export interface IntegrationApplicationState extends EntityState<IntegrationApplication>{
  loading: boolean
  currentApplication: string | null
}

const adapter: EntityAdapter<IntegrationApplication> = createEntityAdapter<IntegrationApplication>()

const initialState: IntegrationApplicationState = adapter.getInitialState({
  loading: false,
  currentApplication: null,
})

const applicationReducer = createReducer(
  initialState,
  on(
    IntegrationApplicationActions.loadAllApplications,
    IntegrationApplicationActions.createApplication,
    IntegrationApplicationActions.updateApplication,
    IntegrationApplicationActions.deleteApplication,
    ConnectionActions.loadConnection,
    (state) => ({
      ...state,
      loading: true,
    }),
  ),
  on(IntegrationApplicationActions.loadAllApplicationsSuccess, (state, { applications }) => {
    return adapter.setAll(applications, {
      ...state,
      loading: false,
    })
  }),
  on(
    IntegrationApplicationActions.loadAllApplicationsFailure,
    IntegrationApplicationActions.createApplicationFailure,
    IntegrationApplicationActions.updateApplicationFailure,
    IntegrationApplicationActions.deleteApplicationFailure,
    (state) => ({
      ...state,
      loading: false,
    }),
  ),
  on(
    IntegrationApplicationActions.loadApplicationFailure,
    (state) => ({
      ...state,
      currentApplication: null,
      loading: false,
    }),
  ),
  on(IntegrationApplicationActions.loadApplication, (state, { id }) => ({
    ...state,
    currentApplication: id,
    loading: true,
  })),
  on(
    IntegrationApplicationActions.loadApplicationSuccess,
    IntegrationApplicationActions.createApplicationSuccess,
    IntegrationApplicationActions.updateApplicationSuccess,
    (state, { application }) => {
      return adapter.upsertOne(application, {
        ...state,
        loading: false,
      })
    }),
  on(IntegrationApplicationActions.deleteApplicationSuccess, (state, { application }) => {
    return adapter.removeOne(application.id, { ...state, loading: false })
  }),
)

export function reducer(state: IntegrationApplicationState | undefined, action: Action) {
  return applicationReducer(state, action)
}

const {
  selectEntities,
  selectAll,
} = adapter.getSelectors()

export const selectApplicationEntities = selectEntities
export const selectAllApplications = selectAll
