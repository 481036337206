import { Injectable } from '@angular/core'
import { AvailableSoftware } from 'app/models'

import { RestService } from './rest.service'

@Injectable({
  providedIn: 'root',
})
export class AvailableDefinitionService {
  constructor(private restService: RestService) {}

  getAll() {
    return this.restService.get<AvailableSoftware[]>(`/available-definitions`)
  }
}
