import { Injectable } from '@angular/core'
import { createFeatureSelector, createSelector, Store } from '@ngrx/store'
import { IntegrationJob } from 'app/models'
import { combineLatestWith } from 'rxjs'
import { map } from 'rxjs/operators'

import { AppState } from '../'
import { JobHistoryState, selectAllJobs } from '../reducers/job-history.reducer'
import { getCurrentConnection } from './connection.selectors'

const getJobHistoryState = createFeatureSelector<JobHistoryState>('jobHistory')

const getJobs = createSelector(
  getJobHistoryState,
  selectAllJobs,
)

const getLoading = createSelector(
  getJobHistoryState,
  (state) => state.loading,
)

const getQueryParam = createSelector(
  getJobHistoryState,
  getCurrentConnection,
  (jobHistoryState, currentConnection) => {
    const params: { pageSize: number, lastEvaluatedKey?: string, connectionId?: string } = { pageSize: jobHistoryState.pageSize }

    if (jobHistoryState.lastEvaluatedKey) {
      params.lastEvaluatedKey = jobHistoryState.lastEvaluatedKey
    }

    if (currentConnection) {
      params.connectionId = currentConnection.id
    }

    return params
  },
)

const hasNext = createSelector(
  getJobHistoryState,
  (state) => !!state.lastEvaluatedKey,
)

@Injectable({
  providedIn: 'root',
})
export class JobHistorySelectors {
  jobs$ = this.store.select(getJobs)
  loading$ = this.store.select(getLoading)
  hasNext$ = this.store.select(hasNext)
  queryParam$ = this.store.select(getQueryParam)
  jobsWithMeta$ = this.jobs$.pipe(
    combineLatestWith(this.store.select(hasNext)),
    map(([jobs, hasNext]): { jobs: IntegrationJob[], hasNext: boolean } => ({
      jobs,
      hasNext,
    })),
  )
  constructor(private store: Store<AppState>) {}
}
