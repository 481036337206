export const templateRuleConnectionId = 'template'
export type transformationRuleType = 'projection' | 'map' | 'set' | 'delete'
export type transformationRuleDirection = 'in' | 'out' | 'all'

export interface MapDefinition {
  [mapTo: string]: string[]
}

export interface TransformationRule {
  id: string
  connectionId: string
  name: string
  ruleType: transformationRuleType
  direction: transformationRuleDirection
  targetList: string[]
  enabled: boolean
  replaceExisting: boolean
  sourceList?: string[]
  mapDefinition?: MapDefinition
  createdAt: Date
  updatedAt: Date
}
