import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import { AvailableDefinitionActions } from '../actions'
import { AppState } from '../reducers/index'

@Injectable({
  providedIn: 'root',
})
export class AvailableDefinitionDispatchers {
  constructor(private store: Store<AppState>) {}

  loadAll() {
    this.store.dispatch(AvailableDefinitionActions.loadAll())
  }
}
