import { Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { MessageHistoryService } from 'app/core/services'
import { of } from 'rxjs'
import { catchError, filter, map, switchMap } from 'rxjs/operators'

import { ConnectionActions, MessageHistoryActions } from '../actions'
import { MessageHistorySelectors } from '../selectors'

@Injectable()
export class MessageHistoryEffects {
  load$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          MessageHistoryActions.loadMessages,
          MessageHistoryActions.refresh,
        ),
        concatLatestFrom(() => this.messageHistorySelectors.queryParam$),
        switchMap(([, params]) => {
          return this.messageHistoryService.getAll(params).pipe(
            map((response) => {
              return MessageHistoryActions.loadMessagesSuccess(response)
            }),
            catchError(() => {
              return of(MessageHistoryActions.loadMessagesFailure({ error: 'Cannot load all logs' }))
            }),
          )
        }),
      ),
  )

  pageUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MessageHistoryActions.pageUpdate),
        concatLatestFrom(() => this.messageHistorySelectors.messagesWithMeta$),
        filter(([{ expectedTotal }, messages]) => expectedTotal > messages.messages.length && messages.hasNext),
        map(() => MessageHistoryActions.loadMessages()),
      ),
  )

  messageSent$ = createEffect(() => this.actions$.pipe(
    ofType(ConnectionActions.sendMessageSuccess),
    map(() => MessageHistoryActions.loadMessages()),
  ))

  constructor(
    private actions$: Actions,
    private messageHistoryService: MessageHistoryService,
    private messageHistorySelectors: MessageHistorySelectors,
  ) {}
}
