import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSliderModule } from '@angular/material/slider'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatSortModule } from '@angular/material/sort'
import { MatStepperModule } from '@angular/material/stepper'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterModule } from '@angular/router'
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength'
import { MaskitoModule } from '@maskito/angular'
import { NgxChartsModule } from '@swimlane/ngx-charts'
import {
  CanSendMessagePipe,
  CanStartConnectionPipe,
  CanStopConnectionPipe,
  ConnectionIsStoppedPipe,
  ConnectionStatusColorPipe,
  ConnectionStatusTextPipe,
  ConnectionTypeTextPipe,
  CopyIncomingToPipe,
  FhirClientTypePipe,
  LastMessageSentAt,
  MaskTextPipe,
} from 'app/core/pipes' // todo fix project structure
import { DragulaModule } from 'ng2-dragula'
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search'

import { ApplicationsTableComponent } from './components/applications-table'
import { ConfigurationFormComponent } from './components/configuration-form'
import { ConnectionListComponent } from './components/connection-list'
import { AppConfirmComponent } from './components/dialogs/app-confirm/app-confirm.component'
import { ConnectionDialogComponent } from './components/dialogs/connection/connection-dialog.component'
import { JobDialogComponent } from './components/dialogs/job'
import { LogsTableComponent } from './components/logs-table'
import { MMXIconComponent } from './components/mmx-icon/mmx-icon.component'
import { AccordionAnchorDirective, AccordionDirective, AccordionLinkDirective } from './directives'
import { ShortIdPipe } from './pipes/short-id.pipe'

const thirdPartyModules = [
  MaskitoModule,
  NgxChartsModule, // todo check if we really need this dep
  NgxMatSelectSearchModule,
]
const materialModulesToExport = [
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
]

const pipesToExport = [
  CanSendMessagePipe,
  CanStartConnectionPipe,
  CanStopConnectionPipe,
  ConnectionIsStoppedPipe,
  ConnectionStatusColorPipe,
  ConnectionStatusTextPipe,
  ConnectionTypeTextPipe,
  CopyIncomingToPipe,
  FhirClientTypePipe,
  LastMessageSentAt,
  MaskTextPipe,
  ShortIdPipe,
]

@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    AppConfirmComponent,
    ConnectionDialogComponent,
    MMXIconComponent,
    CanSendMessagePipe,
    CanStartConnectionPipe,
    CanStopConnectionPipe,
    ConnectionIsStoppedPipe,
    ConnectionStatusColorPipe,
    CopyIncomingToPipe,
    FhirClientTypePipe,
    LastMessageSentAt,
    ApplicationsTableComponent,
    ConnectionListComponent,
    ConfigurationFormComponent,
    LogsTableComponent,
    JobDialogComponent,
    ...pipesToExport,
  ],
  imports: [
    CommonModule,
    RouterModule,
    DragulaModule.forRoot(), // todo check if we really need this dep
    FlexLayoutModule,
    FormsModule,
    MatPasswordStrengthModule,
    ReactiveFormsModule,
    ...materialModulesToExport,
    ...thirdPartyModules,
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    DragulaModule,
    FlexLayoutModule,
    FormsModule,
    MatPasswordStrengthModule,
    ReactiveFormsModule,
    MMXIconComponent,
    ApplicationsTableComponent,
    ConnectionListComponent,
    ConfigurationFormComponent,
    LogsTableComponent,
    JobDialogComponent,
    ...materialModulesToExport,
    ...thirdPartyModules,
    ...pipesToExport,
  ],
  providers: [],
})
export class SharedModule {}
