import { createAction, props } from '@ngrx/store'
import { HistoryMessage, PagedResponse } from 'app/models'

enum MessageHistoryActionTypes {
  LOAD = '[MessageHistory] Load',
  LOAD_SUCCESS = '[MessageHistory] Load Success',
  LOAD_FAILURE = '[MessageHistory] Load Failure',

  PAGE_UPDATE = '[MessageHistory] PAGE_UPDATE',

  RESET = '[MessageHistory] Reset',
  REFRESH = '[MessageHistory] Refresh',
}

export const loadMessages = createAction(MessageHistoryActionTypes.LOAD)
export const loadMessagesSuccess = createAction(MessageHistoryActionTypes.LOAD_SUCCESS, props<PagedResponse<HistoryMessage>>())
export const loadMessagesFailure = createAction(MessageHistoryActionTypes.LOAD_FAILURE, props<{ error: string }>())

export const pageUpdate = createAction(MessageHistoryActionTypes.PAGE_UPDATE, props<{ pageSize: number, expectedTotal: number }>())

export const reset = createAction(MessageHistoryActionTypes.RESET)
export const refresh = createAction(MessageHistoryActionTypes.REFRESH)
