import { Injectable } from '@angular/core'
import { Action, Store } from '@ngrx/store'

import * as AuthActions from '../actions/auth.actions'
import { AppState } from '../reducers/index'

@Injectable()
export class AuthDispatchers {
  constructor(private store: Store<AppState>) {}

  logIn(email: string, password: string) {
    this.dispatch(AuthActions.logIn({ email, password }))
  }

  logOut() {
    this.dispatch(AuthActions.logOut())
  }

  sessionExpired(message: string) {
    this.dispatch(AuthActions.sessionExpired({ message }))
  }

  private dispatch = (action: Action) => this.store.dispatch(action)
}
