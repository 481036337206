import { Injectable } from '@angular/core'
import { HistoryMessage, PagedResponse } from 'app/models'

import { RestService } from './rest.service'

@Injectable({
  providedIn: 'root',
})
export class MessageHistoryService {

  constructor(private restService: RestService) {}

  getAll(queryParams?: any) {
    const route = '/message-history'

    return this.restService.get<PagedResponse<HistoryMessage>>(route, queryParams)
  }
}
