import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { RPADefinition } from 'app/models'

import { RPADefinitionActions } from '../actions'

export interface RPADefinitionState extends EntityState<RPADefinition>{
  loading: boolean
  pageSize: number
  lastEvaluatedKey: string | null
}

const adapter: EntityAdapter<RPADefinition> = createEntityAdapter<RPADefinition>()

const initialState: RPADefinitionState = adapter.getInitialState({
  loading: false,
  pageSize: 20,
  lastEvaluatedKey: null,
})

const definitionReducer = createReducer(
  initialState,
  on(
    RPADefinitionActions.loadDefinitions,
    RPADefinitionActions.createDefinition,
    RPADefinitionActions.updateDefinition,
    RPADefinitionActions.deleteDefinition,
    (state) => ({
      ...state,
      loading: true,
    }),
  ),
  on(RPADefinitionActions.loadDefinitionsSuccess, (state, { data, lastEvaluatedKey }) => {
    return adapter.addMany(data, { ...state, loading: false, lastEvaluatedKey })
  }),
  on(
    RPADefinitionActions.loadDefinitionsFailure,
    RPADefinitionActions.createDefinitionFailure,
    RPADefinitionActions.updateDefinitionFailure,
    RPADefinitionActions.deleteDefinitionFailure,
    (state) => ({
      ...state,
      loading: false,
    }),
  ),
  on(RPADefinitionActions.createDefinitionSuccess, (state, { definition }) => adapter.addOne(definition, {
    ...state,
    loading: false,
  })),
  on(RPADefinitionActions.updateDefinitionSuccess, (state, { definition }) => adapter.upsertOne(definition, {
    ...state,
    loading: false,
  })),
  on(RPADefinitionActions.deleteDefinitionSuccess, (state, { definition }) => adapter.removeOne(definition.id, {
    ...state,
    loading: false,
  })),
  on(RPADefinitionActions.pageUpdate, (state, { pageSize }) => {
    if (state.pageSize !== pageSize) {
      return {
        ...state,
        pageSize,
      }
    }

    return state
  }),
  on(RPADefinitionActions.reset, (state) => adapter.removeAll({ ...state, lastEvaluatedKey: null })),
  on(RPADefinitionActions.refresh, (state) => adapter.removeAll({ ...state, lastEvaluatedKey: null, loading: true })),
)

export function reducer(state: RPADefinitionState | undefined, action: Action) {
  return definitionReducer(state, action)
}

const {
  selectAll,
} = adapter.getSelectors()

export const selectAllDefinitions = selectAll
