import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { JobService } from 'app/core/services'
import { of } from 'rxjs'
import { catchError, filter, map, switchMap } from 'rxjs/operators'

import { ConnectionActions, JobHistoryActions } from '../actions'
import { JobHistorySelectors } from '../selectors'

@Injectable()
export class JobHistoryEffects {
  load$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          JobHistoryActions.loadJobs,
          JobHistoryActions.refresh,
        ),
        concatLatestFrom(() => this.jobHistorySelectors.queryParam$),
        switchMap(([, params]) => {
          return this.jobHistoryService.getHistory(params).pipe(
            map((response) => {
              return JobHistoryActions.loadJobsSuccess(response)
            }),
            catchError(() => {
              return of(JobHistoryActions.loadJobsFailure({ error: 'Cannot load all jobs' }))
            }),
          )
        }),
      ),
  )

  create$ = createEffect(() =>
    () =>
      this.actions$.pipe(
        ofType(JobHistoryActions.createJob),
        switchMap(({ job }) => this.jobHistoryService.create(job).pipe(
          map((job) => {
            this.dialog.closeAll()
            this.snackBar.open('Job successfully started', 'close', { duration: 3000 })
            return JobHistoryActions.createJobSuccess({ job })
          }),
          catchError(() => {
            this.snackBar.open('Failed to create job', 'close', { duration: 3000 })
            return of(JobHistoryActions.loadJobsFailure({ error: 'Cannot create job' }))
          }),
        )),
      ),
  )

  pageUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(JobHistoryActions.pageUpdate),
        concatLatestFrom(() => this.jobHistorySelectors.jobsWithMeta$),
        filter(([{ expectedTotal }, jobs]) => expectedTotal > jobs.jobs.length && jobs.hasNext),
        map(() => JobHistoryActions.loadJobs()),
      ),
  )

  messageSent$ = createEffect(() => this.actions$.pipe(
    ofType(ConnectionActions.sendMessageSuccess),
    map(() => JobHistoryActions.loadJobs()),
  ))

  constructor(
    private actions$: Actions,
    private jobHistoryService: JobService,
    private jobHistorySelectors: JobHistorySelectors,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {}
}
