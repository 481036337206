import { Injectable } from '@angular/core'
import { TransformationRule } from 'app/models'

import { RestService } from './rest.service'

@Injectable({
  providedIn: 'root',
})
export class RuleService {

  private route = '/rule'

  constructor(private restService: RestService) {}

  getRules(queryParams: { connectionId: string }) {
    return this.restService.get<TransformationRule[]>(this.route, queryParams)
  }

  createRule(rule: Partial<TransformationRule>) {
    return this.restService.post<TransformationRule>(this.route, rule)
  }

  updateRule(rule: TransformationRule) {
    return this.restService.put<TransformationRule>(`${this.route}/${rule.id}`, rule)
  }

  deleteRule(rule: TransformationRule) {
    return this.restService.delete(`${this.route}/${rule.id}`, { connectionId: rule.connectionId })
  }
}
