import { createAction, props } from '@ngrx/store'
import { IntegrationLog, PagedResponse } from 'app/models'

enum LogActionTypes {
  LOAD = '[Logs] Load',
  LOAD_SUCCESS = '[Logs] Load Success',
  LOAD_FAILURE = '[Logs] Load Failure',

  PAGE_UPDATE = '[Logs] PAGE_UPDATE',

  RESET = '[Logs] Reset',
  REFRESH = '[Logs] Refresh',
}

const createLogErrorAction = (actionType: string) =>
  createAction(actionType, props<{ error: string }>())

export const loadLogs = createAction(LogActionTypes.LOAD)
export const loadLogsSuccess = createAction(LogActionTypes.LOAD_SUCCESS, props<PagedResponse<IntegrationLog>>())
export const loadLogsFailure = createLogErrorAction(LogActionTypes.LOAD_FAILURE)

export const pageUpdate = createAction(LogActionTypes.PAGE_UPDATE, props<{ pageSize: number, expectedTotal: number }>())

export const reset = createAction(LogActionTypes.RESET)
export const refresh = createAction(LogActionTypes.REFRESH)
