import { Injectable } from '@angular/core'
import { IntegrationLog, PagedResponse } from 'app/models'

import { RestService } from './rest.service'

@Injectable()
export class LogService {

  constructor(private restService: RestService) {}

  getAll(queryParams?: any) {
    const route = '/log'

    return this.restService.get<PagedResponse<IntegrationLog>>(route, queryParams)
  }
}
