import { FhirConfiguration } from '@integrations/shared'

import { IntegrationCustomer } from './integration-customer'

export enum IntegrationConnectionType {
  HL7 = 'hl7',
  FHIR = 'fhir',
  RPA = 'rpa',
}

export enum HL7ConnectionDirection {
  IN = 'in',
  OUT = 'out',
  BI = 'bi',
  SFTP_IN = 'sftp_in',
}

export enum HL7ConnectionStatus {
  RUNNING = 'RUNNING',
  CLOSED = 'CLOSED',
  FAILED = 'FAILED',
}
export interface HL7ConnectionConfiguration {
  incomingPort?: number
  outgoingHost?: string
  outgoingPort?: number
  ignoreAck?: boolean
  prefixFrame?: string
  suffixFrame?: string
  newLineSeparator?: string
}

export interface HL7SftpConfiguration {
  host: string
  port?: number
  username: string
  password?: string
  privateKey?: string
  workingDir: string
}

export enum FhirClientType {
  Epic,
  EpicSandbox,
  Cerner,
  CernerOpenSandbox
}

export enum RpaSoftware {
  AllScripts = 'allscripts',
  Avreo = 'avreo',
  CernerPM = 'cerner.pm',
  Eagle = 'eagle',
  EpicPM = 'epic.pm',
  Exa = 'exa',
  Greenway = 'greenway',
  Infinitt = 'infinitt',
  Meditech = 'meditech',
  MergeDocumentManager = 'merge.mdm',
  MergeRis = 'merge.ris',
  MergeScheduler = 'merge.scheduler',
  MergeUnity = 'merge.unity',
  RainTree = 'raintree',
  RamSoftPowerReader = 'ramsoft.powerreader',
  RamSoftRapidResults = 'ramsoft.rapidresults',
  STAR = 'star',
  Synapse6 = 'synapse6',
  Synapse7 = 'synapse7',
  TheraOffice = 'theraoffice.web',
  YouCanBookMe = 'youcanbookme',
}

export interface RpaConfiguration {
  url: string
  username: string
  password: string
}

export interface IntegrationConnection {
  id: string
  // @TODO: rename to applicationId
  clientId: string
  name: string
  connectionType: IntegrationConnectionType
  copyIncomingTo: string[]
  createdAt: Date
  updatedAt: Date
  customerId: string
  subnet?: 'vpc192'
  lastMessageTimestamp?: Date
  callbackEndpoint?: string
  hl7ConnectionStatus?: HL7ConnectionStatus
  hl7ConnectionDirection?: HL7ConnectionDirection
  hl7ConnectionConfiguration?: HL7ConnectionConfiguration
  hl7OutgoingAddressMap?: Record<string, { host: string, port: number }>
  hl7SftpConfiguration?: HL7SftpConfiguration
  fhirClientType?: FhirClientType
  fhirConfiguration?: FhirConfiguration
  rpaSoftware?: RpaSoftware
  rpaConfiguration?: RpaConfiguration
}

export interface IntegrationConnectionWithCustomer extends IntegrationConnection {
  customer?: IntegrationCustomer
  applicationName?: string
}
