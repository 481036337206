import { Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { LogService } from 'app/core/services/log.service'
import { of } from 'rxjs'
import { catchError, filter, map, switchMap } from 'rxjs/operators'

import * as LogActions from '../actions/log.actions'
import { LogSelectors } from '../selectors'

@Injectable()
export class LogEffects {
  loadAll$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          LogActions.loadLogs,
          LogActions.refresh,
        ),
        concatLatestFrom(() => this.logSelectors.queryParam$),
        switchMap(([, params]) => {
          return this.logService.getAll(params).pipe(
            map((response) => {
              return LogActions.loadLogsSuccess(response)
            }),
            catchError(() => {
              return of(LogActions.loadLogsFailure({ error: 'Cannot load all logs' }))
            }),
          )
        }),
      ),
  )

  pageUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LogActions.pageUpdate),
        concatLatestFrom(() => this.logSelectors.logsWithMeta$),
        filter(([{ expectedTotal }, logs]) => expectedTotal > logs.logs.length && logs.hasNext),
        map(() => LogActions.loadLogs()),
      ),
  )

  constructor(
    private actions$: Actions,
    private logService: LogService,
    private logSelectors: LogSelectors,
  ) {}
}
