import { Injectable } from '@angular/core'
import { createFeatureSelector, createSelector, Store } from '@ngrx/store'

import { AppState } from '../'
import { IntegrationApplicationState, selectAllApplications, selectApplicationEntities } from '../reducers/integration-application.reducer'

export const getApplicationState = createFeatureSelector<IntegrationApplicationState>('application')

export const getApplications = createSelector(
  getApplicationState,
  selectAllApplications,
)

export const getCurrentApplicationId = createSelector(
  getApplicationState,
  (state) => state.currentApplication,
)

export const getApplicationEntities = createSelector(
  getApplicationState,
  selectApplicationEntities,
)

export const getCurrentApplication = createSelector(
  getApplicationEntities,
  getCurrentApplicationId,
  (entities, applicationId) => applicationId && entities[applicationId],
)

export const getApplicationLoading = createSelector(
  getApplicationState,
  (state: IntegrationApplicationState) => state.loading,
)

@Injectable()
export class IntegrationApplicationSelectors {
  applications$ = this.store.select(getApplications)
  currentApplication$ = this.store.select(getCurrentApplication)
  loading$ = this.store.select(getApplicationLoading)
  constructor(private store: Store<AppState>) {}
}
