export const environment = {
  env: 'gamma',
  production: true,
  version: '1.0.0',
  api: 'https://api.gamma.mmxintegrations.com',
  sentryDSN: 'https://907e9dcc17cc47e3b2d61dde085258e7@sentry.io/1792550',
  cognito: {
    userPoolId: 'us-east-1_WAT62xGpR',
    userPoolWebClientId: '7i2nj5s7kvrbcimd2iimrl2us1', // Located in App integration tab
  },
}
