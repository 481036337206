import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { IntegrationApplication } from 'app/models'

import * as IntegrationApplicationActions from '../actions/integration-application.actions'
import { AppState } from '../reducers/index'

@Injectable()
export class IntegrationApplicationDispatchers {
  constructor(private store: Store<AppState>) {}

  create(applicationName: string) {
    this.store.dispatch(IntegrationApplicationActions.createApplication({ name: applicationName }))
  }

  update(application: IntegrationApplication) {
    this.store.dispatch(IntegrationApplicationActions.updateApplication({ application }))
  }

  load(id: string) {
    this.store.dispatch(IntegrationApplicationActions.loadApplication({ id }))
  }

  loadAll() {
    this.store.dispatch(IntegrationApplicationActions.loadAllApplications())
  }

  delete(application: IntegrationApplication) {
    this.store.dispatch(IntegrationApplicationActions.deleteApplication({ application }))
  }
}
