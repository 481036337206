import { createAction, props } from '@ngrx/store'
import { PagedResponse, RPADefinition } from 'app/models'

enum RPADefinitionActionTypes {
  LOAD = '[RPA Definition] Load',
  LOAD_SUCCESS = '[RPA Definition] Load Success',
  LOAD_FAILURE = '[RPA Definition] Load Failure',

  CREATE = '[RPA Definition] Create',
  CREATE_SUCCESS = '[RPA Definition] Create Success',
  CREATE_FAILURE = '[RPA Definition] Create Failure',

  UPDATE = '[RPA Definition] Update',
  UPDATE_SUCCESS = '[RPA Definition] Update Success',
  UPDATE_FAILURE = '[RPA Definition] Update Failure',

  DELETE = '[RPA Definition] Delete',
  DELETE_SUCCESS = '[RPA Definition] Delete Success',
  DELETE_FAILURE = '[RPA Definition] Delete Failure',

  PAGE_UPDATE = '[RPA Definition] PAGE_UPDATE',

  RESET = '[RPA Definition] Reset',
  REFRESH = '[RPA Definition] Refresh',
}

export const loadDefinitions = createAction(RPADefinitionActionTypes.LOAD, props<{ connectionId: string }>())
export const loadDefinitionsSuccess = createAction(RPADefinitionActionTypes.LOAD_SUCCESS, props<PagedResponse<RPADefinition>>())
export const loadDefinitionsFailure = createAction(RPADefinitionActionTypes.LOAD_FAILURE, props<{ error: string }>())

export const createDefinition = createAction(RPADefinitionActionTypes.CREATE, props<{ definition: Partial<RPADefinition> }>())
export const createDefinitionSuccess = createAction(RPADefinitionActionTypes.CREATE_SUCCESS, props<{ definition: RPADefinition }>())
export const createDefinitionFailure = createAction(RPADefinitionActionTypes.CREATE_FAILURE, props<{ error: string }>())

export const updateDefinition = createAction(RPADefinitionActionTypes.UPDATE, props<{ definition: RPADefinition }>())
export const updateDefinitionSuccess = createAction(RPADefinitionActionTypes.UPDATE_SUCCESS, props<{ definition: RPADefinition }>())
export const updateDefinitionFailure = createAction(RPADefinitionActionTypes.UPDATE_FAILURE, props<{ error: string }>())

export const deleteDefinition = createAction(RPADefinitionActionTypes.DELETE, props<{ definition: RPADefinition }>())
export const deleteDefinitionSuccess = createAction(RPADefinitionActionTypes.DELETE_SUCCESS, props<{ definition: RPADefinition }>())
export const deleteDefinitionFailure = createAction(RPADefinitionActionTypes.DELETE_FAILURE, props<{ error: string }>())

export const pageUpdate = createAction(RPADefinitionActionTypes.PAGE_UPDATE, props<{ pageSize: number, expectedTotal: number }>())

export const reset = createAction(RPADefinitionActionTypes.RESET)
export const refresh = createAction(RPADefinitionActionTypes.REFRESH)
