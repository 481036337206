import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { RuleService } from 'app/core/services'
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import * as RuleActions from '../actions/rule.actions'

@Injectable()
export class RuleEffects {
  loadRules$ = createEffect(() => this.actions$.pipe(
    ofType(RuleActions.loadRules),
    switchMap(({ connectionId }) => this.ruleService.getRules({ connectionId }).pipe(
      map((rules) => RuleActions.loadRulesSuccess({ rules })),
      catchError(() => {
        this.snackBar.open('Load rules failure', 'close', { duration: 3000 })
        return of(RuleActions.loadRulesFailure({ error: 'Load rules failure' }))
      })),
    )),
  )

  createRule$ = createEffect(() => this.actions$.pipe(
    ofType(RuleActions.createRule),
    switchMap(({ rule }) => this.ruleService.createRule(rule).pipe(
      map((newRule) => {
        this.dialog.closeAll()
        return RuleActions.createRuleSuccess({ rule: newRule })
      }),
      catchError(() => {
        this.snackBar.open('Create rule failure', 'close', { duration: 3000 })
        return of(RuleActions.loadRulesFailure({ error: 'Create rule failure' }))
      }),
    )),
  ))

  updateRule$ = createEffect(() => this.actions$.pipe(
    ofType(RuleActions.updateRule),
    switchMap(({ rule }) => this.ruleService.updateRule(rule).pipe(
      map((newRule) => {
        this.dialog.closeAll()
        return RuleActions.updateRuleSuccess({ rule: newRule })
      }),
      catchError(() => {
        this.snackBar.open('Update rule failure', 'close', { duration: 3000 })
        return of(RuleActions.updateRuleFailure({ error: 'Update rule failure' }))
      }),
    )),
  ))

  deleteRule$ = createEffect(() => this.actions$.pipe(
    ofType(RuleActions.deleteRule),
    switchMap(({ rule }) => this.ruleService.deleteRule(rule).pipe(
      map(() => {
        return RuleActions.deleteRuleSuccess({ rule })
      }),
      catchError(() => {
        this.snackBar.open('Delete rule failure', 'close', { duration: 3000 })
        return of(RuleActions.deleteRuleFailure({ error: 'Delete rule failure' }))
      }),
    )),
  ))

  constructor(
    private actions$: Actions,
    private ruleService: RuleService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {}
}
