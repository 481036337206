import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { AuthDispatchers } from '../../store/dispatchers/auth.dispatchers'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authDispatchers: AuthDispatchers) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          this.authDispatchers.sessionExpired('Token expired')
        }
        return throwError(err)
      }),
    )
  }
}
