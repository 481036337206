<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5">
  <img src="assets/images/users/profile.png" alt="user">
</button>
<mat-menu #profile="matMenu" class="mymegamenu">
<!--  <button mat-menu-item [routerLink]="['/profile']">-->
<!--    <mat-icon>account_box</mat-icon>-->
<!--    Profile-->
<!--  </button>-->
  <button mat-menu-item (click)="logOut()">
    <mat-icon>exit_to_app</mat-icon>
    Log Out
  </button>
</mat-menu>

