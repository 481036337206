import { Injectable } from '@angular/core'
import { PagedResponse, RPADefinition } from 'app/models'

import { RestService } from './rest.service'

@Injectable({
  providedIn: 'root',
})
export class RPADefinitionService {
  constructor(private restService: RestService) {}

  getDefinitions(connectionId: string) {
    return this.restService.get<PagedResponse<RPADefinition>>(this.getBaseRoute(connectionId))
  }

  createDefinition(definition: Partial<RPADefinition>) {
    return this.restService.post<RPADefinition>(this.getBaseRoute(definition.connectionId), definition)
  }

  updateDefinition(definition: Partial<RPADefinition>) {
    return this.restService.put<RPADefinition>(
      `${this.getBaseRoute(definition.connectionId)}/${definition.id}`,
      definition,
    )
  }

  deleteDefinition(definition: Partial<RPADefinition>) {
    return this.restService.delete(
      `${this.getBaseRoute(definition.connectionId)}/${definition.id}`,
    )
  }

  private getBaseRoute(connectionId: string) {
    return `/connection/${connectionId}/definition`
  }
}
