import { Injectable } from '@angular/core'
import { IntegrationJob, PagedResponse } from 'app/models'

import { RestService } from './rest.service'

@Injectable({
  providedIn: 'root',
})
export class JobService {
  constructor(private restService: RestService) {}

  create(job: Partial<IntegrationJob>) {
    const route = `/connection/${job.connectionId}/job`

    return this.restService.post<IntegrationJob>(route, job)
  }

  getHistory(queryParams?: any) {
    const route = `/connection/${queryParams.connectionId}/job`

    return this.restService.get<PagedResponse<IntegrationJob>>(route, queryParams)
  }
}
