import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { from } from 'rxjs'
import { map } from 'rxjs/operators'

import { CognitoService } from './cognito.service'

@Injectable()
export class AuthGuardService {
  constructor(
    private cognitoService: CognitoService,
    private router: Router,
  ) {}

  canActivate() {
    return from(this.cognitoService.isAuthenticated()).pipe(
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          return this.router.createUrlTree(['/log-in'])
        }

        return true
      }),
    )
  }
}
