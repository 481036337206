import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-mmx-icon',
  templateUrl: './mmx-icon.component.html',
  styleUrls: ['./mmx-icon.component.scss'],
})
export class MMXIconComponent {
  @Input() paused = false
  @Input() color = 'primary'

  get classes() {
    return {
      [this.color]: true,
      paused: this.paused,
    }
  }
}
