import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import * as LogActions from '../actions/log.actions'
import { AppState } from '../reducers/index'

@Injectable()
export class LogDispatchers {
  constructor(private store: Store<AppState>) {}

  load() {
    this.store.dispatch(LogActions.loadLogs())
  }

  pageSizeUpdate(pageSize: number, expectedTotal: number) {
    this.store.dispatch(LogActions.pageUpdate({ pageSize, expectedTotal }))
  }

  reset() {
    this.store.dispatch(LogActions.reset())
  }

  refresh() {
    this.store.dispatch(LogActions.refresh())
  }
}
