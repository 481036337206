import { createAction, props } from '@ngrx/store'
import { IntegrationJob, PagedResponse } from 'app/models'

enum JobHistoryActionTypes {
  LOAD = '[JobHistory] Load',
  LOAD_SUCCESS = '[JobHistory] Load Success',
  LOAD_FAILURE = '[JobHistory] Load Failure',

  PAGE_UPDATE = '[JobHistory] PAGE_UPDATE',

  RESET = '[JobHistory] Reset',
  REFRESH = '[JobHistory] Refresh',

  CREATE = '[JobHistory] Create',
  CREATE_SUCCESS = '[JobHistory] Create Success',
  CREATE_FAILURE = '[JobHistory] Create Failure',
}

const createJobAction = (actionType: string) =>
  createAction(actionType, props<{ job: IntegrationJob }>())

export const loadJobs = createAction(JobHistoryActionTypes.LOAD)
export const loadJobsSuccess = createAction(JobHistoryActionTypes.LOAD_SUCCESS, props<PagedResponse<IntegrationJob>>())
export const loadJobsFailure = createAction(JobHistoryActionTypes.LOAD_FAILURE, props<{ error: string }>())

export const pageUpdate = createAction(JobHistoryActionTypes.PAGE_UPDATE, props<{ pageSize: number, expectedTotal: number }>())

export const reset = createAction(JobHistoryActionTypes.RESET)
export const refresh = createAction(JobHistoryActionTypes.REFRESH)

export const createJob = createAction(JobHistoryActionTypes.CREATE, props<{ job: Partial<IntegrationJob> }>())
export const createJobSuccess = createJobAction(JobHistoryActionTypes.CREATE_SUCCESS)
export const createJobFailure = createAction(JobHistoryActionTypes.CREATE_FAILURE, props<{ error: string }>())
