import { Injectable } from '@angular/core'
import { createFeatureSelector, createSelector, Store } from '@ngrx/store'
import { IntegrationConnection, IntegrationConnectionWithCustomer } from 'app/models'
import { createHash, createHmac, randomBytes } from 'crypto'
import { utc } from 'moment-timezone'

import { AppState } from '../reducers'
import { ConnectionState, selectAllConnections, selectConnectionEntities } from '../reducers/connection.reducer'
import { getApplicationEntities, getApplicationLoading } from './integration-application.selectors'
import { getCustomers } from './integration-customer.selectors'

const getConnectionState = createFeatureSelector<ConnectionState>('connection')

export const getAllConnections = createSelector(
  getConnectionState,
  getCustomers,
  (state, customers): IntegrationConnectionWithCustomer[] => {
    const connections = selectAllConnections(state)

    return connections.map((connection) => ({
      ...connection,
      customer: connection.customerId ? customers.find((c) => c.id === connection.customerId) : undefined,
    }))
  },
)

const getCurrentConnectionId = createSelector(
  getConnectionState,
  (state) => state.currentConnection,
)

const getConnectionEntities = createSelector(
  getConnectionState,
  selectConnectionEntities,
)

export const getCurrentConnection = createSelector(
  getCurrentConnectionId,
  getConnectionEntities,
  getCustomers,
  (id, entities, customers): IntegrationConnectionWithCustomer => {
    const connection = id && entities[id]
    if (!connection) {
      return
    }

    return {
      ...connection,
      customer: connection.customerId ? customers.find((c) => c.id === connection.customerId) : undefined,
    }
  },
)

const getConnectionsLoading = createSelector(
  getConnectionState,
  (state: ConnectionState) => state.loading,
)

const getConnectionsOrApplicationLoading = createSelector(
  getConnectionsLoading,
  getApplicationLoading,
  (connectionsLoading, applicationsLoading) => connectionsLoading || applicationsLoading,
)

const getApplicationConnections = createSelector(
  getAllConnections,
  getApplicationEntities,
  (connections, applicationEntities): IntegrationConnectionWithCustomer[] => connections
    .map((connection) => ({
      ...connection,
      applicationName: applicationEntities[connection.clientId]?.name,
    })),
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getMessageSignature = (message: string, controlId: string, connection: IntegrationConnection) => createSelector(
  getApplicationEntities,
  (applicationEntities) => {
    const application = applicationEntities[connection.clientId]

    const checksum = createHash('sha256')
      .update(message, 'utf8')
      .digest('hex')
    const nonce = randomBytes(32).toString('base64')
    const timestamp = utc().unix()
    const hmac = createHmac('sha256', application?.secretKey)
    hmac.update(`${connection.id}::${controlId}::${timestamp}::${nonce}`)
    const signature = hmac.digest('hex')
    const params = {
      s: signature,
      d: timestamp,
      n: nonce,
      c: checksum,
    }

    return btoa(JSON.stringify(params))
  },
)

const getHL7ServerIp = createSelector(
  getConnectionState,
  (state) => state.hl7ServerIp,
)

const getCustomerConnections = (customerId: string) => createSelector(
  getAllConnections,
  (connections) => connections.filter((app) => app.customerId === customerId),
)

@Injectable()
export class ConnectionSelectors {
  connections$ = this.store.select(getAllConnections)
  applicationConnections$ = this.store.select(getApplicationConnections)
  currentConnection$ = this.store.select(getCurrentConnection)
  loading$ = this.store.select(getConnectionsOrApplicationLoading)
  hl7ServerIp$ = this.store.select(getHL7ServerIp)

  constructor(private store: Store<AppState>) {}

  getMessageSignature(message: string, controlId: string, connection: IntegrationConnection) {
    return this.store.select(getMessageSignature(message, controlId, connection))
  }

  getCustomerConnections(customerId: string) {
    return this.store.select(getCustomerConnections(customerId))
  }
}
