import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { API } from 'aws-amplify'
import * as _ from 'lodash'
import { from, Observable } from 'rxjs'

import { environment } from '../../../environments/environment'

interface IRestResponseError {
  field: string
  message?: string
}

export interface IRestResponse<T> {
  success: boolean
  data: T
  message?: string
  errors?: IRestResponseError[]
  lastEvaluatedId?: string
}

export interface IHttpRequestOptions {
  headers?: { [key: string]: string }
  queryStringParameters?: { [key: string]: string }
  reportProgress?: boolean
  responseType?: 'json'
  withCredentials?: boolean
  body?: any
}

@Injectable()
export class RestService {
  constructor(protected http: HttpClient) {}

  get<T>(route: string, params?: { [key: string]: string }, options: IHttpRequestOptions = {}): Observable<T> {
    options.queryStringParameters = params
    options = this.getDefaultOptions(options)

    return from(API.get('myAPIName', route, options))
  }

  post<T>(route: string, body: any, options: IHttpRequestOptions = {}): Observable<T> {
    options.body = body
    options = this.getDefaultOptions(options)

    return from(API.post('myAPIName', route, options))
  }

  put<T>(route: string, body: any, options: IHttpRequestOptions = {}): Observable<T> {
    options.body = body
    options = this.getDefaultOptions(options)
    return from(API.put('myAPIName', route, options))
  }

  delete<T>(route: string, body?: any, options: IHttpRequestOptions = {}): Observable<T> {
    options.body = body
    options = this.getDefaultOptions(options)
    return from(API.del('myAPIName', route, options))
  }

  getApiUrl(route: string = '') {
    return this.getBaseApiUrl() + route
  }

  protected getDefaultOptions(options: IHttpRequestOptions): IHttpRequestOptions {
    return _.extend({
      responseType: 'json',
    }, options)
  }

  private getBaseApiUrl() {
    return environment.api
  }
}
