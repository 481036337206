import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'
import { IntegrationLog } from 'app/models'

import * as LogActions from '../actions/log.actions'

export interface LogState extends EntityState<IntegrationLog>{
  loading: boolean
  pageSize: number
  lastEvaluatedKey: string | null
}

const adapter: EntityAdapter<IntegrationLog> = createEntityAdapter<IntegrationLog>()

const initialState: LogState = adapter.getInitialState({
  loading: false,
  pageSize: 20,
  lastEvaluatedKey: null,
})

const logReducer = createReducer(
  initialState,
  on(
    LogActions.loadLogs,
    (state) => ({
      ...state,
      loading: true,
    })),
  on(LogActions.loadLogsSuccess, (state, { data, lastEvaluatedKey }) => {
    return adapter.addMany(data, { ...state, loading: false, lastEvaluatedKey })
  }),
  on(LogActions.loadLogsFailure, (state) => ({
    ...state,
    loading: false,
  })),
  on(LogActions.pageUpdate, (state, { pageSize }) => {
    if (state.pageSize !== pageSize) {
      return {
        ...state,
        pageSize,
      }
    }

    return state
  }),
  on(LogActions.reset, (state) => adapter.removeAll({ ...state, lastEvaluatedKey: null })),
  on(LogActions.refresh, (state) => adapter.removeAll({ ...state, lastEvaluatedKey: null, loading: true })),
)

export function reducer(state: LogState | undefined, action: Action) {
  return logReducer(state, action)
}

const {
  selectAll,
} = adapter.getSelectors()

export const selectAllLogs = selectAll
